const SkeletonHeader = () => (
  <div className="skeleton-header">
    <div className="shimmer" />
  </div>
);

const SkeletonCard = () => (
  <div className="skeleton-card">
    <div className="shimmer" />
  </div>
);

export const SkeletonCardGrid = () => {
  return (
    <div>
      <SkeletonHeader />
      <div className="skeleton-container">
        <div className="skeleton-grid">
          {Array.from({ length: 20 }).map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};