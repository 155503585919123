import { useState} from "react";
import { formatName } from "./utils";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

export const RecommendedCard = ({ calling, zoomedOut, onReview, onApprove, deletePendingCalling, sessionInfo, unitDetails, defaultBorderColor = false, membersList, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = calling.member_photo;
  let householdPhotoSrc = calling.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  const reviewRequired = sessionInfo.accessLevel === "BISHOPRIC" && sessionInfo.canIssueCallings && calling.recommender_id !== sessionInfo.userId && !calling.reviewer_ids.includes(sessionInfo.userId)

  if (zoomedOut && ["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel)) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#2684FC"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <DropdownMenu>
            <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "-1px", right: "-7px", zIndex: 1000}} asChild>
              <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "22px" }}></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="dropdown-content"
              align="end"
              side="bottom"      
              style={{zIndex: 1000}}
            >
              <DropdownMenuRadioGroup>
                <DropdownMenuRadioItem
                  className='dropdown-item'
                  onClick={() => deletePendingCalling(calling.id)}
                  style={{ fontSize: "16px" }}
                >
                  <i className="bx bx-trash" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Eliminar" : "Delete"}
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <div
            style={{
              height: "150px",
              width: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {calling.notes?.length > 0 && (
              <Tippy content={calling.notes.map(n => n.note).join(" • ")} theme="lcrplus">
                <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
              </Tippy>
            )}
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(calling.recommended_name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)}>
            {formatName(calling.recommended_name)}
          </div>
          <div style={{
            fontSize: "13px",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            fontStyle: "italic",
            width: "150px",
          }}>
            {calling.name}
          </div>
        </div>
        {showDetails && <MemberDetails member={calling} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
      </>
    );
  }

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#2684FC"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "150px",
              width: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {calling.notes?.length > 0 && (
              <Tippy content={calling.notes.map(n => n.note).join(" • ")} theme="lcrplus">
                <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
              </Tippy>
            )}
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(calling.recommended_name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)}>
            {formatName(calling.recommended_name)}
          </div>
          <div
            style={{
              fontSize: "13px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontStyle: "italic",
              width: "150px",
            }}
          >
            {calling.name}
          </div>
        </div>
        {showDetails && <MemberDetails member={calling} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#2684FC"}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "-1px", right: "-7px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "22px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              <DropdownMenuRadioItem
                className='dropdown-item'
                onClick={() => deletePendingCalling(calling.id)}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-trash" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar" : "Delete"}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {calling.notes?.length > 0 && (
            <Tippy content={calling.notes.map(n => n.note).join(" • ")} theme="lcrplus">
              <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
            </Tippy>
          )}
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(calling.recommended_name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{formatName(calling.recommended_name)}</strong>
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            fontStyle: "italic",
          }}
        >
          {calling.name}
        </div>
        <div style={{ fontSize: "14px", display: "flex" }}>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Recomendado por:" : "Recommended by:"}</strong>
            </div>
            <div>{calling.recommender_title}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          {!unitDetails.async_approval_enabled && (
              <button onClick={() => onApprove(calling.id)} className="cta">
                {unitDetails.language === "es" ? "Aprobar" : "Approve"}
              </button>
          )}
          {unitDetails.async_approval_enabled && reviewRequired && (
            <button onClick={() => onReview(calling.id)} className="cta">
              {unitDetails.language === "es" ? "Aprobar" : "Approve"}
            </button>
          )}
          {unitDetails.async_approval_enabled && !reviewRequired && (
            <div>
              <div>
                <strong>{unitDetails.language === "es" ? "Esperando:" : "Awaiting:"}</strong>
              </div>
              <div>{calling.reviewer_count - calling.reviewer_ids.length} {calling.reviewer_count - calling.reviewer_ids.length === 1 ? ` ${unitDetails.language === "es" ? "revisión" : "review"}` : ` ${unitDetails.language === "es" ? "revisiones" : "reviews"}`}</div>
            </div>
          )}
        </div>
      </div>
      {showDetails && <MemberDetails member={calling} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </>
  );
};