import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling

// https://simplicable.com/colors/earth-tone
export const CallingsByOrganizationLegend = ({ unitDetails, sessionInfo }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <div></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#2684FC",
              backgroundColor: "#2684FC",
            }}
          />
          <strong>
            {unitDetails.language === "es" ? "Llamamiento en progreso" : "Pending calling"}
            <Tippy content={unitDetails.language === "es" ? "Recomendaciones de llamamientos que necesitan ser aprobadas, extendidas, sostenidas o apartadas" : "Calling recommendations that need to be approved, extended, sustained, or set apart"} theme="lcrplus">
              <i className="bx bx-info-circle" style={{ marginLeft: '5px', fontSize: "14px" }}></i>
            </Tippy>
          </strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#d988bb",
              backgroundColor: "#d988bb",
            }}
          />
          <strong>{unitDetails.shorter_tenure_label}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "16px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#a33b7d",
              backgroundColor: "#a33b7d",
            }}
          />
          <strong>{unitDetails.longer_tenure_label}</strong>
        </div>
        {(sessionInfo.accessLevel === "BISHOPRIC") &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="bx bx-square-rounded"
              style={{
                marginRight: "4px",
                fontSize: "16px",
                borderRadius: "4px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#ea4335",
                backgroundColor: "#ea4335",
              }}
            />
            <strong>{unitDetails.language === "es" ? "Despedida en progreso" : "Pending release"}</strong>
          </div>
        }
        <div></div>
      </div>
    </div>
  );
};