import { useState } from "react";
import { Welcome } from "./Welcome";
import { Onboarding } from "./Onboarding";
import { TithingDeclaration } from "./TithingDeclaration";
import { TempleRecommends } from "./TempleRecommends";
import { SettingAparts } from "./SettingAparts";
import { PreviousBishops } from "./PreviousBishops";
import { NextBishops } from "./NextBishops";
import { Birthdays } from "./Birthdays";
import { NoPictures } from "./NoPictures";
import { Ministering } from "./Ministering";

export const MessageTemplates = ({ sessionInfo, unitDetails }) => {
  const getInitialTab = () => {
    if (sessionInfo.isWelcomeNotificationRecipient) return "welcome";
    if (sessionInfo.isOnboardingNotificationRecipient) return "onboarding";
    if (sessionInfo.isTithingDeclarationNotificationRecipient) return "tithingDeclaration";
    if (sessionInfo.isTempleRecommendNotificationRecipient) return "templeRecommends";
    if (sessionInfo.isSettingApartNotificationRecipient && sessionInfo.settingApartNotificationIncludeLinks) return "settingAparts";
    if (sessionInfo.isPreviousBishopNotificationRecipient) return "previousBishops";
    if (sessionInfo.isNextBishopNotificationRecipient) return "nextBishops";
    if (sessionInfo.isBirthdayNotificationRecipient) return "birthdays";
    if (sessionInfo.isNoPicturesNotificationRecipient) return "noPictures";
    if (sessionInfo.isMinisteringNotificationRecipient) return "ministering";
    return null;
  };

  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState(getInitialTab());

  return (
    <>
      <div className="template-menu">
        {sessionInfo.isWelcomeNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("welcome")} className={selectedMessageTemplate === "welcome" ? "active" : ""}>{unitDetails.language === "es" ? "Bienvenida" : "Welcome"}</button>}
        {sessionInfo.isOnboardingNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("onboarding")} className={selectedMessageTemplate === "onboarding" ? "active" : ""}>{unitDetails.language === "es" ? "Incorporación" : "Onboarding"}</button>}
        {sessionInfo.isTithingDeclarationNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("tithingDeclaration")} className={selectedMessageTemplate === "tithingDeclaration" ? "active" : ""}>{unitDetails.language === "es" ? "Declaración de diezmos" : "Tithing declaration"}</button>}
        {sessionInfo.isTempleRecommendNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("templeRecommends")} className={selectedMessageTemplate === "templeRecommends" ? "active" : ""}>{unitDetails.language === "es" ? "Recomendaciones de templos" : "Temple recommends"}</button>}
        {sessionInfo.isSettingApartNotificationRecipient && sessionInfo.settingApartNotificationIncludeLinks && <button onClick={() => setSelectedMessageTemplate("settingAparts")} className={selectedMessageTemplate === "settingAparts" ? "active" : ""}>{unitDetails.language === "es" ? "Apartamientos" : "Setting aparts"}</button>}
        {sessionInfo.isPreviousBishopNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("previousBishops")} className={selectedMessageTemplate === "previousBishops" ? "active" : ""}>{unitDetails.language === "es" ? "Obispos anteriores" : "Previous bishops"}</button>}
        {sessionInfo.isNextBishopNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("nextBishops")} className={selectedMessageTemplate === "nextBishops" ? "active" : ""}>{unitDetails.language === "es" ? "Obispos siguientes" : "Next bishops"}</button>}
        {sessionInfo.isBirthdayNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("birthdays")} className={selectedMessageTemplate === "birthdays" ? "active" : ""}>{unitDetails.language === "es" ? "Cumpleaños" : "Birthdays"}</button>}
        {sessionInfo.isNoPicturesNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("noPictures")} className={selectedMessageTemplate === "noPictures" ? "active" : ""}>{unitDetails.language === "es" ? "Fotos" : "No pictures"}</button>}
        {sessionInfo.isMinisteringNotificationRecipient && <button onClick={() => setSelectedMessageTemplate("ministering")} className={selectedMessageTemplate === "ministering" ? "active" : ""}>{unitDetails.language === "es" ? "Ministerio" : "Ministering"}</button>}
      </div>
      {selectedMessageTemplate === "welcome" && <Welcome unitDetails={unitDetails} />}
      {selectedMessageTemplate === "onboarding" && <Onboarding unitDetails={unitDetails} />}
      {selectedMessageTemplate === "tithingDeclaration" && <TithingDeclaration unitDetails={unitDetails} />}
      {selectedMessageTemplate === "templeRecommends" && <TempleRecommends unitDetails={unitDetails} />}
      {selectedMessageTemplate === "settingAparts" && <SettingAparts unitDetails={unitDetails} />}
      {selectedMessageTemplate === "previousBishops" && <PreviousBishops unitDetails={unitDetails} />}
      {selectedMessageTemplate === "nextBishops" && <NextBishops unitDetails={unitDetails} />}
      {selectedMessageTemplate === "birthdays" && <Birthdays unitDetails={unitDetails} />}
      {selectedMessageTemplate === "noPictures" && <NoPictures unitDetails={unitDetails} />}
      {selectedMessageTemplate === "ministering" && <Ministering unitDetails={unitDetails} />}
    </>
  )
}