import { useState, useEffect, useRef } from "react";
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import * as Tabs from '@radix-ui/react-tabs';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { SkeletonFlashcard } from "./SkeletonFlashcard";
import PortraitRoundedIcon from '@mui/icons-material/PortraitRounded';
import FontDownloadRoundedIcon from '@mui/icons-material/FontDownloadRounded';


export const Flashcards = ({ unitDetails, sessionInfo, membersList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [mode, setMode] = useState('classic_mode');
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const initialLoad = useRef(true);
  const [flashcards, setFlashcards] = useState([]);
  const [flashcardsToReview, setFlashcardsToReview] = useState([]);
  const [multipleChoiceType, setMultipleChoiceType] = useState('names');

  const filteredMembers = membersList.filter(member => {
    const hasPhoto = member.member_photo || member.household_photo;
    if (!hasPhoto) return false;

    if (sessionInfo.accessLevel === 'EQ_PRESIDENCY') {
      return member.gender === 'M';
    }
    if (sessionInfo.accessLevel === 'RS_PRESIDENCY') {
      return member.gender === 'F'; 
    }
    return true;
  });

  async function deleteFlashcards() {
    await fetch('/api/delete_flashcards', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mode }),
    });
  }

  async function fetchFlashcards() {
    const response = await fetch('/api/list_flashcards');
    const data = await response.json();
    setFlashcards(data);
    setFlashcardsToReview(filteredMembers.filter(member => !data.some(f => f.subject_id === member.member_id && f.mode === mode)));
    setCorrectCount(data.filter(f => f.mode === mode && f.is_correct).length);
    setIncorrectCount(data.filter(f => f.mode === mode && !f.is_correct).length);
  }
  
  if (initialLoad.current) {
    const loadData = async () => {
      await fetchFlashcards();
    };

    loadData();
    initialLoad.current = false;
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && mode === 'classic_mode' && !isTransitioning) {
        event.preventDefault();
        setIsFlipped(!isFlipped);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFlipped, mode, isTransitioning]);

  const getMultipleChoiceOptions = (member) => {
    const otherMembers = filteredMembers
      .filter(m => m.member_id !== member.member_id && m.gender === member.gender)
      .sort(() => Math.random() - 0.5)
      .slice(0, 2);
    
    const options = [...otherMembers, member]
      .sort(() => Math.random() - 0.5)
      .map(m => ({
        name: `${m.name.split(',')[1].trim().split(' ')[0]} ${m.name.split(',')[0]}`,
        photo: m.member_photo || m.household_photo,
        isCorrect: m === member
      }));
    
    return options;
  };

  const recordFlashcard = async (memberId, isCorrect) => {
    try {
      await fetch('/api/record_flashcard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ memberId, mode, isCorrect }),
      });
      setFlashcards(prev => [...prev, { mode, subject_id: memberId, is_correct: isCorrect }]);
    } catch (error) {
      console.error('Failed to record flashcard:', error);
    }
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    setShowFeedback(true);

    recordFlashcard(flashcardsToReview[currentIndex].member_id, answer.isCorrect);
    if (answer.isCorrect) {
      setCorrectCount(correctCount + 1);
    } else {
      setIncorrectCount(incorrectCount + 1);
    }
    
    if (answer.isCorrect) {
      setTimeout(() => {
        handleNext(true);
      }, 500);
    }
  };

  const handleNext = (isCorrect) => {
    setIsTransitioning(true);
    setIsFlipped(false);
    setSelectedAnswer(null);
    setShowFeedback(false);

    if (mode === 'classic_mode') {
      recordFlashcard(flashcardsToReview[currentIndex].member_id, isCorrect);
      if (isCorrect) {
        setCorrectCount(correctCount + 1);
      } else {
        setIncorrectCount(incorrectCount + 1);
      }
    }

    setCurrentIndex((prevIndex) => 
      prevIndex === flashcardsToReview.length - 1 ? 0 : prevIndex + 1
    );
    if (mode === 'multiple_choice') {
      setCurrentOptions(getMultipleChoiceOptions(flashcardsToReview[currentIndex === flashcardsToReview.length - 1 ? 0 : currentIndex + 1]));
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  const handleFlip = () => {
    if (!isTransitioning && mode === 'classic_mode') {
      setIsFlipped(!isFlipped);
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setCurrentIndex(0);
    setFlashcardsToReview(filteredMembers.filter(member => !flashcards.some(f => f.subject_id === member.member_id && f.mode === newMode)));
    setCorrectCount(flashcards.filter(f => f.mode === newMode && f.is_correct).length);
    setIncorrectCount(flashcards.filter(f => f.mode === newMode && !f.is_correct).length);

    if (newMode === 'multiple_choice') {
      setCurrentOptions(getMultipleChoiceOptions(filteredMembers.filter(member => !flashcards.some(f => f.subject_id === member.member_id && f.mode === newMode))[0]));
    }
  };

  const handleRestart = async () => {
    await deleteFlashcards();
    await fetchFlashcards();
    setCurrentIndex(0);
    if (mode === 'multiple_choice') {
      setCurrentOptions(getMultipleChoiceOptions(filteredMembers[0]));
    }
  };

  const handleShuffle = () => {
    const shuffledCards = filteredMembers
      .filter(member => !flashcards.some(f => f.subject_id === member.member_id && f.mode === mode))
      .sort(() => Math.random() - 0.5);
    
    setFlashcardsToReview(shuffledCards);
    setCorrectCount(flashcards.filter(f => f.mode === mode && f.is_correct).length);
    setIncorrectCount(flashcards.filter(f => f.mode === mode && !f.is_correct).length);
    
    if (mode === 'multiple_choice') {
      setCurrentOptions(getMultipleChoiceOptions(shuffledCards[currentIndex]));
    }
  };

  if (filteredMembers.length === 0) {
    return <div>{unitDetails.language === 'es' ? 'No se encontraron miembros con fotos' : 'No members with photos found'}</div>;
  }

  if (flashcardsToReview.length === 0) {
    return <SkeletonFlashcard />;
  }

  const currentMember = flashcardsToReview[currentIndex];

  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        <div style={{ marginBottom: '10px' }}>
          <Tabs.Root style={{ display: 'flex', gap: '8px' }}>
            <Tabs.List style={{ display: 'flex', backgroundColor: "#F6F7F8", borderRadius: '6px', height: "36px" }}>
              <Tabs.Trigger
                value={'classic_mode'}
                onClick={() => handleModeChange('classic_mode')}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  color: mode === 'classic_mode' ? '#0E121B' : '#99A0AE',
                  backgroundColor: mode === 'classic_mode' ? 'white' : '#F6F7F8',
                  cursor: 'pointer',
                  margin: '4px',
                  transition: 'background-color 0.2s',
                  fontSize: '14px',
                  fontWeight: '500',
                  width: '145px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  boxShadow: mode === 'classic_mode' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                }}
              >
                {unitDetails.language === 'es' ? 'Modo clásico' : 'Classic mode'}
              </Tabs.Trigger>
              <Tabs.Trigger
                value={'multiple_choice'}
                onClick={() => handleModeChange('multiple_choice')}
                style={{
                  border: 'none',
                  borderRadius: '4px',
                  color: mode === 'multiple_choice' ? '#0E121B' : '#99A0AE',
                  backgroundColor: mode === 'multiple_choice' ? 'white' : '#F6F7F8',
                  cursor: 'pointer',
                  margin: '4px',
                  transition: 'background-color 0.2s',
                  fontSize: '14px',
                  fontWeight: '500',
                  width: '145px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  boxShadow: mode === 'multiple_choice' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                }}
              >
                {unitDetails.language === 'es' ? 'Opción múltiple' : 'Multiple choice'}
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </div>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          width: '100%',
          fontSize: '12px',
        }}>
          <div style={{ color: '#FF8733', fontWeight: '600' }}>
            <span style={{ border: '1px solid #FF8733', padding: '2px 8px', borderRadius: '8px', marginRight: '6px', backgroundColor: '#FFEDD5' }}>{incorrectCount}</span>
            {unitDetails.language === 'es' ? 'Todavía aprendiendo' : 'Still learning'}
          </div>
          <div style={{ color: '#12B981', fontWeight: '600' }}>
            {unitDetails.language === 'es' ? 'Sé' : 'Know'}
            <span style={{ border: '1px solid #12B981', padding: '2px 8px', borderRadius: '8px', marginLeft: '6px', backgroundColor: '#C9FFF6' }}>{correctCount}</span>
          </div>
        </div>
        {mode === 'classic_mode' ? (
          <div
            className={`flashcard ${isFlipped ? 'flipped' : ''} ${isTransitioning ? 'transitioning' : ''}`}
            onClick={handleFlip}
          >
            <div className='flashcard-front'>
              <img 
                src={currentMember.member_photo || currentMember.household_photo} 
              />
            </div>
            <div className='flashcard-back'>
              <div className='text-center'>
                <h2>{currentMember.name.split(',')[1].trim().split(' ')[0]}</h2>
                <h2>{currentMember.name.split(',')[0]}</h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
            {multipleChoiceType === 'names' ? (
              <div style={{ width: '300px', height: '300px', border: '3px solid #B0B5C0', borderRadius: '8px', position: 'relative', cursor: 'default' }}>
                <div className='flashcard-front'>
                  <img 
                    src={currentMember.member_photo || currentMember.household_photo} 
                  />
                </div>
              </div>
            ) : (
              <div style={{ width: '300px', height: '300px', border: '3px solid #B0B5C0', borderRadius: '8px', position: 'relative', cursor: 'default', color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='text-center'>
                  <h2>{currentMember.name.split(',')[1].trim().split(' ')[0]}</h2>
                  <h2>{currentMember.name.split(',')[0]}</h2>
                </div>
              </div>
            )}

            {multipleChoiceType === 'names' ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
                {currentOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleAnswerSelect(option)}
                    disabled={showFeedback}
                    style={{
                      padding: '8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      background: showFeedback
                        ? option.isCorrect 
                          ? '#d4edda' 
                          : selectedAnswer === option 
                            ? '#f8d7da'
                            : '#fff'
                        : '#fff',
                      cursor: showFeedback ? 'default' : 'pointer',
                    }}
                  >
                    {option.name}
                  </button>
                ))}
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', justifyContent: 'center', width: '100%' }}>
                {currentOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleAnswerSelect(option)}
                    disabled={showFeedback}
                    style={{
                      width: '96px',
                      height: '96px',
                      padding: 0,
                      border: '1px solid #B0B5C0',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      position: 'relative',
                      cursor: showFeedback ? 'default' : 'pointer',
                    }}
                  >
                    <img 
                      src={option.photo}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    {showFeedback && (
                      <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        opacity: 0.8
                      }}>
                        {option.isCorrect ? (
                          <CheckRoundedIcon style={{ fontSize: 48, color: '#12B981' }} />
                        ) : selectedAnswer === option ? (
                          <CloseRoundedIcon style={{ fontSize: 48, color: '#FF8733' }} />
                        ) : null}
                      </div>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}

        {mode === 'multiple_choice' && (
          <div style={{ display: 'flex', gap: '16px', color: 'black', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center', position: 'relative', width: '100%', justifyContent: 'center' }}>
              <button
                className="flashcard-button"
                onClick={handleRestart}
                style={{
                  border: '1px solid #E1E4EA', 
                  borderRadius: '10px',
                  background: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#99A0AE',
                  padding: '4px',
                  position: 'absolute',
                  left: 0
                }}
              >
                <RestartAltRoundedIcon style={{ fontSize: '24px' }} />
              </button>
              <Tabs.Root defaultValue={multipleChoiceType} style={{ display: 'flex', gap: '8px' }}>
                <Tabs.List style={{ display: 'flex', backgroundColor: "#F6F7F8", borderRadius: '6px', height: "36px" }}>
                  <Tabs.Trigger
                    value={'names'}
                    onClick={() => setMultipleChoiceType('names')}
                    style={{
                      border: 'none',
                      borderRadius: '4px',
                      color: multipleChoiceType === 'names' ? 'black' : '#99A0AE',
                      backgroundColor: multipleChoiceType === 'names' ? 'white' : '#F6F7F8',
                      cursor: 'pointer',
                      margin: '4px',
                      transition: 'background-color 0.2s',
                      fontSize: '14px',
                      fontWeight: '500',
                      width: '96px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: multipleChoiceType === 'names' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                    }}
                  >
                    <FontDownloadRoundedIcon style={{ color: "#99A0AE" }} />
                    <span>{unitDetails.language === "es" ? "Nombres" : "Names"}</span>
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    value={'photos'}
                    onClick={() => setMultipleChoiceType('photos')}
                    style={{
                      border: 'none',
                      borderRadius: '4px',
                      color: multipleChoiceType === 'photos' ? 'black' : '#99A0AE',
                      backgroundColor: multipleChoiceType === 'photos' ? 'white' : '#F6F7F8',
                      cursor: 'pointer',
                      margin: '4px',
                      transition: 'background-color 0.2s',
                      fontSize: '14px',
                      fontWeight: '500',
                      width: '96px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      boxShadow: multipleChoiceType === 'photos' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                    }}
                  >
                    <PortraitRoundedIcon style={{ color: "#99A0AE" }} />
                    <span>{unitDetails.language === "es" ? "Fotos" : "Photos"}</span>
                  </Tabs.Trigger>
                </Tabs.List>
              </Tabs.Root>
              <button
                className='flashcard-button'
                onClick={handleShuffle}
                style={{
                  border: '1px solid #E1E4EA', 
                  borderRadius: '10px',
                  background: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#99A0AE',
                  padding: '4px',
                  position: 'absolute',
                  right: 0
                }}
              >
                <ShuffleRoundedIcon style={{ fontSize: '24px' }} />
              </button>
            </div>
          </div>
        )}

        {mode === 'classic_mode' && (
          <div style={{ display: 'flex', gap: '16px', color: 'black', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center', position: 'relative', width: '100%', justifyContent: 'center' }}>
              <button
                className='flashcard-button'
                onClick={handleRestart}
                style={{
                  border: '1px solid #E1E4EA', 
                  borderRadius: '10px',
                  background: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#99A0AE',
                  padding: '4px',
                  position: 'absolute',
                  left: 0
                }}
              >
                <RestartAltRoundedIcon style={{ fontSize: '24px' }} />
              </button>
              <div style={{ display: 'flex', gap: '16px' }}>
                <button
                  className='flashcard-button'
                  onClick={() => handleNext(false)} 
                  style={{ 
                    border: '1px solid #E1E4EA',
                    borderRadius: '10px',
                    background: 'none', 
                    display: 'flex', 
                    alignItems: 'center',
                    color: '#FF8733',
                    padding: '4px',
                  }}
                >
                  <CloseRoundedIcon style={{ fontSize: '24px' }} />
                </button>
                <button 
                  className='flashcard-button'
                  onClick={() => handleNext(true)} 
                  style={{
                    border: '1px solid #E1E4EA',
                    borderRadius: '10px',
                    background: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#12B981',
                    padding: '4px',
                  }}
                >
                  <CheckRoundedIcon style={{ fontSize: '24px' }} />
                </button>
              </div>
              <button
                className='flashcard-button'
                onClick={handleShuffle}
                style={{
                  border: '1px solid #E1E4EA', 
                  borderRadius: '10px',
                  background: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#99A0AE',
                  padding: '4px',
                  position: 'absolute',
                  right: 0
                }}
              >
                <ShuffleRoundedIcon style={{ fontSize: '24px' }} />
              </button>
            </div>
          </div>
        )}

        {mode === 'multiple_choice' && selectedAnswer && !(selectedAnswer?.isCorrect) && (
          <div style={{ display: 'flex', gap: '16px', color: 'black', alignItems: 'center' }}>
            <button 
              onClick={() => handleNext(selectedAnswer?.isCorrect)} 
              style={{ 
                border: 'none', 
                background: 'none', 
                display: 'flex', 
                alignItems: 'center',
                gap: '4px'
              }}
            >
              <span>{unitDetails.language === 'es' ? 'Continuar' : 'Continue'}</span>
              <ArrowForwardRoundedIcon style={{ fontSize: '16px' }} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}