import React, { useState } from "react";
import Select from "react-select";
import { formatName } from "../utils";
import { NoProfilePicture } from "../NoProfilePicture";

export const AddMinisteringCard = ({
  onAdd,
  endpoint,
  companionshipId,
  gender,
  unitDetails,
  membersList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [options, setOptions] = useState([]);

  async function fetchOptions() {
    setIsLoading(true);
    try {
      const res = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ companionshipId, gender }),
      });
      const optionIds = await res.json();
      setOptions(
        membersList
          .filter((member) => optionIds.includes(member.value))
          .map((member) => ({
            ...member,
            label: formatName(member.label),
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (error) {
      console.error("Error executing query", error);
    } finally {
      setIsLoading(false);
    }
  }

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "2px",
          cursor: "pointer",
          borderBottom: "0.5px solid #e0e0e0",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            marginRight: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.member_photo || data.household_photo ?
            <img
              src={
                data.member_photo || data.household_photo
              }
              alt={data.label}
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
                borderRadius: "5px",
                border: "0.5px solid #B0B5C0",
              }}
            /> : <NoProfilePicture />
          }
        </div>
        <span style={{ flex: 1, textAlign: "left" }}>{data.label}</span>
      </div>
    );
  };

  const customMenuList = (props) => {
    return isLoading ? <i className="bx bx-loader-alt loading-spinner"></i> : <div {...props} />;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "210px",
      left: "50%",
      transform: "translateX(-50%)",
      maxHeight: "240px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  if (!isAdding) {
    return (
      <div
        className="add-card"
        style={{ cursor: "pointer", height: "170px", width: "150px" }}
        onClick={() => setIsAdding(true)}
        title={unitDetails.language === "es" ? "Agregar miembro" : "Add member"}
      >
        <div className="bx bx-plus-circle" style={{ fontSize: "24px" }}></div>
      </div>
    );
  }

  return (
    <div
      className="add-card"
      style={{
        width: "150px",
        height: "170px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Select
        menuPortalTarget={document.body}
        menuPosition="fixed"
        value={null}
        onChange={(option) => {
          onAdd(companionshipId, option.value);
          setIsAdding(false);
        }}
        onMenuOpen={() => fetchOptions()}
        options={options}
        placeholder={unitDetails.language === "es" ? "Miembro" : "Member"}
        styles={selectStyles}
        components={{
          Option: CustomOption,
          MenuList: customMenuList,
        }}
      />
    </div>
  );
};
