const SkeletonRow = () => (
  <div className="skeleton-row">
    <div className="shimmer" />
  </div>
);

const TopLoader = () => (
  <div style={{
    width: "250px",
    height: "38px",
    backgroundColor: "#EEF0F4",
    borderRadius: "4px",
    position: "relative",
    overflow: "hidden",
    marginBottom: "16px"
  }}>
    <div className="shimmer" />
  </div>
);

export const SkeletonRows = () => {
  return (
    <div className="skeleton-rows-container">
      <TopLoader />
      {Array.from({ length: 15 }).map((_, index) => (
        <SkeletonRow key={index} />
      ))}
    </div>
  );
};