import { useState, useEffect, useRef } from "react";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';

export const NoPictures = ({ unitDetails }) => {
  const initialLoad = useRef(true);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  async function fetchNoPicturesNotificationDetails() {
    try {
      const res = await fetch("/api/get_no_picture_notification_details")
      const data = await res.json();
      setMessageTemplate(data.message_template);
      setIsLoading(false);
    } catch (error) {
      console.error("Error executing query", error);
      setIsLoading(false);
    }
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchNoPicturesNotificationDetails();
    };

    loadData();
    initialLoad.current = false;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && 
          !emojiPickerRef.current.contains(event.target) &&
          !emojiButtonRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMessageChange = async (e) => {
    const newValue = e.target.value;
    setMessageTemplate(newValue);

    if (!isLoading) {
      await fetch("/api/update_no_picture_notification_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageTemplate: newValue }),
      });
    }
  };

  async function handleSend() {
    try {
      setIsSending(true);
      const res = await fetch("/api/send_no_picture_notification");
      // Handle response if needed
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error sending notification", error);
    } finally {
      setIsSending(false);
    }
  }

  const handleEmojiSelect = async (emoji) => {
    const newValue = messageTemplate + emoji.native;
    setMessageTemplate(newValue);
    setShowEmojiPicker(false);

    if (!isLoading) {
      await fetch("/api/update_no_picture_notification_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageTemplate: newValue }),
      });
    }
  };

  const successToast = (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#22c55e',
        color: 'white',
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        transform: showSuccess ? 'translateY(0)' : 'translateY(-1rem)',
        opacity: showSuccess ? 1 : 0,
        transition: 'all 0.3s ease',
        pointerEvents: showSuccess ? 'auto' : 'none',
        zIndex: 5000,
      }}
    >
      <svg
        style={{
          width: '1.25rem',
          height: '1.25rem',
        }}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
      <span>
        {unitDetails.language === "es" ? "Éxito" : "Success"}
      </span>
    </div>
  );

  return (
    <>
      <div style={{
        width: '350px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div style={{ fontSize: "17px", fontWeight: "600", display: "flex", width: "100%", gap: "13px", justifyContent: "space-between" }}>
          <div style={{ color: "black" }}>
            {unitDetails.language === "es" ? "No fotos" : "No pictures"}
          </div>
          <div style={{ display: "flex", gap: "13px" }}>
            <i
              className="bx bx-smile communication-icon" 
              style={{ 
                fontSize: "26px", 
                cursor: "pointer",
                position: "relative",
              }}
              ref={emojiButtonRef}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              title={unitDetails.language === "es" ? "Añadir emoji" : "Add emoji"}
            />
            {showEmojiPicker && (
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '-27px',
                  zIndex: 1000,
                }}
                ref={emojiPickerRef}
              >
                <Picker 
                  data={data}
                  onEmojiSelect={handleEmojiSelect}
                  theme="dark"
                  previewPosition="none"
                  skinTonePosition="none"
                />
              </div>
            )}
            <button
              onClick={handleSend}
              disabled={isSending}
              style={{
                padding: '2px 6px',
                border: 'none',
                borderRadius: '4px',
                background: '#2684FC',
                color: 'white',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                opacity: isSending ? '0.65' : '1',
                width: '80px',
              }}
            >
              {isSending ? (
                <i className="bx bx-loader-alt loading-spinner" style={{ fontSize: "16px" }} />
              ) : (
                <>
                  {unitDetails.language === "es" ? "Enviar" : "Send"}
                  <SendIcon style={{ fontSize: "16px" }}/>
                </>
              )}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", borderRadius: "4px", border: "1px solid #ccc", marginTop: "10px", alignItems: "flex-start" }}>
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "208px",
                backgroundColor: "#EEF0F4",
                borderRadius: "4px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div className="message-template-shimmer"/>
            </div>
          ) : (
            <textarea
              value={messageTemplate}
              onChange={handleMessageChange}
              maxLength={1000}
              placeholder={unitDetails.language === "es" ? "Plantilla del mensaje" : "Message template"}
              rows="10"
              style={{
                width: "100%",
                fontFamily: "inherit",
                fontSize: "16px",
                resize: "none",
                border: "none",
                outline: "none",
                boxSizing: "border-box",
                marginTop: "8px",
                marginBottom: "8px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "10px" }}>
          <div style={{ display: "flex", textAlign: "start", gap: "6px", color: "black", fontSize: "14px" }}>
            <InfoOutlinedIcon style={{ fontSize: "16px" }}/>
            <span>
              {unitDetails.language === "es" 
                ? "Esta es la plantilla para el mensaje que se copiará en tus textos con el miembro. <NOMBRE> será reemplazado con el nombre del miembro."
                : "This is the template for the message that will be copied into your texts with the member. <NAME> will be replaced with the member's first name."}
            </span>
          </div>
        </div>
      </div>
      {successToast}
    </>
  )
}