import { useState, useEffect, useRef } from "react";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';

export const TithingDeclaration = ({ unitDetails }) => {
  const initialLoad = useRef(true);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchTithingDeclarationDetails() {
    try {
      const res = await fetch("/api/get_tithing_declaration_notification_details")
      const data = await res.json();
      setMessageTemplate(data.message_template);
      setIsLoading(false);
    } catch (error) {
      console.error("Error executing query", error);
      setIsLoading(false);
    }
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchTithingDeclarationDetails();
    };

    loadData();
    initialLoad.current = false;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && 
          !emojiPickerRef.current.contains(event.target) &&
          !emojiButtonRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMessageChange = async (e) => {
    const newValue = e.target.value;
    setMessageTemplate(newValue);

    if (!isLoading) {
      await fetch("/api/update_tithing_declaration_notification_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageTemplate: newValue }),
      });
    }
  };

  const handleEmojiSelect = async (emoji) => {
    const newValue = messageTemplate + emoji.native;
    setMessageTemplate(newValue);
    setShowEmojiPicker(false);

    if (!isLoading) {
      await fetch("/api/update_tithing_declaration_notification_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messageTemplate: newValue }),
      });
    }
  };

  return (
    <>
      <div style={{
        width: '350px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div style={{ fontSize: "17px", fontWeight: "600", display: "flex", width: "100%", gap: "13px", justifyContent: "space-between" }}>
          <div style={{ color: "black" }}>{unitDetails.language === "es" ? "Declaración de diezmos" : "Tithing declaration"}</div>
          <div style={{ display: "flex", gap: "13px" }}>
            <i
              className="bx bx-smile communication-icon" 
              style={{ 
                fontSize: "26px", 
                cursor: "pointer",
                position: "relative",
              }}
              ref={emojiButtonRef}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              title={unitDetails.language === "es" ? "Añadir emoji" : "Add emoji"}
            />
            {showEmojiPicker && (
              <div
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '-27px',
                  zIndex: 1000,
                }}
                ref={emojiPickerRef}
              >
                <Picker 
                  data={data}
                  onEmojiSelect={handleEmojiSelect}
                  theme="dark"
                  previewPosition="none"
                  skinTonePosition="none"
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", borderRadius: "4px", border: "1px solid #ccc", marginTop: "10px", alignItems: "flex-start" }}>
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: "208px",
                backgroundColor: "#EEF0F4",
                borderRadius: "4px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div className="message-template-shimmer"/>
            </div>
          ) : (
            <textarea
              value={messageTemplate}
              onChange={handleMessageChange}
              maxLength={1000}
              placeholder={unitDetails.language === "es" ? "Plantilla del mensaje" : "Message template"}
              rows="10"
              style={{
                width: "100%",
                fontFamily: "inherit",
                fontSize: "16px",
                resize: "none",
                border: "none",
                outline: "none",
                boxSizing: "border-box",
                marginTop: "8px",
                marginBottom: "8px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "6px", color: "black", fontSize: "14px" }}>
            <ScheduleOutlinedIcon style={{ fontSize: "16px" }}/>
            <span>
              {unitDetails.language === "es" 
                ? "Se envía cuando se solicita"
                : "Sends on request"}
            </span>
          </div>
          <div style={{ display: "flex", textAlign: "start", gap: "6px", color: "black", fontSize: "14px" }}>
            <InfoOutlinedIcon style={{ fontSize: "16px" }}/>
            <span>
              {unitDetails.language === "es" 
                ? `Esta es la plantilla para el mensaje que se copiará en tus textos con el miembro. <NAME> será reemplazado con el nombre del miembro.`
                : `This is the template for the message that will be copied into your texts with the member. <NAME> will be replaced with the member's first name.`}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}