import React from "react";
import { MinisteringCard } from "./MinisteringCard";
import { AddMinisteringCard } from "./AddMinisteringCard";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { formatPhoneNumber } from "../utils";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';

export const MinisteringCompanionship = ({ companionship, sessionInfo, unitDetails, zoomedOut, membersList, setMembersList, type, defaultMessage, conditionalLocationClause, companions, assignments, deleteMinisteringCompanionship, createMinisteringAssignment, createMinisteringCompanion, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion }) => {
  const firstNames = companions.filter(row => row.ministering_companionship_id === companionship.id).map(companion => {
    const afterComma = companion.member_name.split(',')[1].trim();
    return afterComma.split(' ')[0];
  });

  const formattedNames = firstNames
    .reduce((formatted, name, index) => {
        if (index === 0) return name;
        if (index === firstNames.length - 1) return `${formatted} and ${name}`;
        return `${formatted}, ${name}`;
    }, '');

    const hasDifferentLocations = companions.filter(row => row.ministering_companionship_id === companionship.id).some((companion, index, array) => {
      if (index === 0) return false;
      return companion.latitude !== array[0].latitude || 
             companion.longitude !== array[0].longitude;
    });

  function createGroupChat(phoneNumbers) {
    let message = defaultMessage?.replace('<NAMES>', formattedNames);
    if (hasDifferentLocations) {
      message += ' ' + conditionalLocationClause;
    }
    const encodedMessage = encodeURIComponent(message);
    const androidUrl = `sms:${phoneNumbers.join(',')}?body=${encodedMessage}`;
    const iOSUrl = `sms:/open?addresses=${phoneNumbers.join(',')}&body=${encodedMessage}`;
    
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod|Mac|Macintosh/i.test(navigator.userAgent);
    
    if (isAndroid) {
        window.location.href = androidUrl;
    } else if (isIOS) {
        window.location.href = iOSUrl;
    } else {
        window.location.href = androidUrl;
    }
  }

  if (type === "RS") {
    return (
      <div style={{ position: "relative" }}>
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-2.5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "22px", color: "black" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {["EQ_PRESIDENCY", "RS_PRESIDENCY", "MINISTERING"].includes(sessionInfo.accessLevel) && /Android|iPhone|iPad|iPod|Mac|Macintosh/.test(navigator.userAgent) &&
                <DropdownMenuRadioItem
                  className="dropdown-item"
                  onClick={() => createGroupChat(companions.filter(row => row.ministering_companionship_id === companionship.id && row.phone_number != null).map(row => formatPhoneNumber(row.phone_number)))}
                  style={{ fontSize: "16px" }}
                >
                  <i className="bx bx-message" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Enviar mensaje a compañeras" : "Message companions"}
                </DropdownMenuRadioItem>
              }
              {!zoomedOut && (companionship.is_auto_generated || companionship.is_manually_created) &&
                <DropdownMenuRadioItem
                  className="dropdown-item"
                  onClick={() => deleteMinisteringCompanionship(companionship.id)}
                  style={{ fontSize: "16px" }}
                >
                  <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Eliminar" : "Delete"}
                </DropdownMenuRadioItem>
              }
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="sub-organization-section ministering-companionship">
          <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              {companions.filter(row => row.ministering_companionship_id === companionship.id).map((companion, index) => (
                <MinisteringCard
                  key={index}
                  member={companion}
                  updateMinisteringAssignment={updateMinisteringAssignment}
                  updateMinisteringCompanion={updateMinisteringCompanion}
                  deleteMinisteringAssignment={deleteMinisteringAssignment}
                  deleteMinisteringCompanion={deleteMinisteringCompanion}
                  sessionInfo={sessionInfo}
                  unitDetails={unitDetails}
                  zoomedOut={zoomedOut}
                  membersList={membersList}
                  setMembersList={setMembersList}
                />
              ))}
              {!zoomedOut &&
                <AddMinisteringCard
                  onAdd={createMinisteringCompanion}
                  endpoint="/api/ministering/list_companion_options"
                  companionshipId={companionship.id}
                  gender="F"
                  unitDetails={unitDetails}
                  membersList={membersList.filter(member => member.gender === "F" && member.wants_to_minister)}
                />
              }
            </div>
            <VolunteerActivismOutlinedIcon sx={{ fontSize: 36, color: "black", marginLeft: "20px", marginRight: "20px" }} />
            <div style={{ display: "flex" }}>
              {assignments.filter(row => row.ministering_companionship_id === companionship.id).map((assignment, index) => (
                <MinisteringCard
                  key={index}
                  member={assignment}
                  updateMinisteringAssignment={updateMinisteringAssignment}
                  updateMinisteringCompanion={updateMinisteringCompanion}
                  deleteMinisteringAssignment={deleteMinisteringAssignment}
                  deleteMinisteringCompanion={deleteMinisteringCompanion}
                  sessionInfo={sessionInfo}
                  unitDetails={unitDetails}
                  zoomedOut={zoomedOut}
                  membersList={membersList}
                  setMembersList={setMembersList}
                />
              ))}
              {!zoomedOut &&
                <AddMinisteringCard
                  onAdd={createMinisteringAssignment}
                  endpoint="/api/ministering/list_assignment_options"
                  companionshipId={companionship.id}
                  gender="F"
                  unitDetails={unitDetails}
                  membersList={membersList.filter(member => member.gender === "F" && member.wants_ministers)}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ position: "relative" }}>
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-2.5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "22px", color: "black" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {["EQ_PRESIDENCY", "RS_PRESIDENCY", "MINISTERING"].includes(sessionInfo.accessLevel) && /Android|iPhone|iPad|iPod|Mac|Macintosh/.test(navigator.userAgent) &&
                <DropdownMenuRadioItem
                  className="dropdown-item"
                  onClick={() => createGroupChat(companions.filter(row => row.ministering_companionship_id === companionship.id && row.phone_number != null).map(row => formatPhoneNumber(row.phone_number)))}
                  style={{ fontSize: "16px" }}
                >
                  <i className="bx bx-message" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Enviar mensaje a compañeros" : "Message companions"}
                </DropdownMenuRadioItem>
              }
              {!zoomedOut && (companionship.is_auto_generated || companionship.is_manually_created) &&
                <DropdownMenuRadioItem
                  className="dropdown-item"
                  onClick={() => deleteMinisteringCompanionship(companionship.id)}
                  style={{ fontSize: "16px" }}
                >
                  <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Eliminar" : "Delete"}
                </DropdownMenuRadioItem>
              }
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="sub-organization-section ministering-companionship">
          <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "10px" }}>
            <div style={{ display: "flex" }}>
              {companions.filter(row => row.ministering_companionship_id === companionship.id).map((companion, index) => (
                <MinisteringCard
                  key={index}
                  member={companion}
                  updateMinisteringAssignment={updateMinisteringAssignment}
                  updateMinisteringCompanion={updateMinisteringCompanion}
                  deleteMinisteringAssignment={deleteMinisteringAssignment}
                  deleteMinisteringCompanion={deleteMinisteringCompanion}
                  sessionInfo={sessionInfo}
                  unitDetails={unitDetails}
                  zoomedOut={zoomedOut}
                  membersList={membersList}
                  setMembersList={setMembersList}
                />
              ))}
              {!zoomedOut &&
                <AddMinisteringCard
                  onAdd={createMinisteringCompanion}
                  endpoint="/api/ministering/list_companion_options"
                  companionshipId={companionship.id}
                  gender="M"
                  unitDetails={unitDetails}
                  membersList={membersList.filter(member => member.gender === "M" && member.wants_to_minister)}
                />
              }
            </div>
            <VolunteerActivismOutlinedIcon sx={{ fontSize: 36, color: "black", marginLeft: "20px", marginRight: "20px" }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                {assignments.filter(row => row.ministering_companionship_id === companionship.id).sort((a, b) => a.gender.localeCompare(b.gender)).map((assignment, index) => (
                  <MinisteringCard
                    key={index}
                    member={assignment}
                    updateMinisteringAssignment={updateMinisteringAssignment}
                    updateMinisteringCompanion={updateMinisteringCompanion}
                    deleteMinisteringAssignment={deleteMinisteringAssignment}
                    deleteMinisteringCompanion={deleteMinisteringCompanion}
                    sessionInfo={sessionInfo}
                    unitDetails={unitDetails}
                    zoomedOut={zoomedOut}
                    membersList={membersList}
                    setMembersList={setMembersList}
                  />
                ))}
                {!zoomedOut &&
                  <AddMinisteringCard
                    onAdd={createMinisteringAssignment}
                    endpoint="/api/ministering/list_assignment_options"
                    companionshipId={companionship.id}
                    unitDetails={unitDetails}
                    membersList={membersList.filter(member => member.wants_ministers)}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    ); 
  }
};
