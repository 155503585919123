import { useRef, useState, useEffect } from "react";
import { formatName } from "./utils";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { MemberDetails } from "./MemberDetails";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { NoProfilePicture } from "./NoProfilePicture";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

export const CalledCard = ({ calling, zoomedOut, onUpdate, deletePendingCalling, unitDetails, sessionInfo, defaultBorderColor = false, membersList, setMembersList }) => {
  const calendarRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [sustainedDate, setSustainedDate] = useState(calling.sustained_date);
  const [isSetApart, setIsSetApart] = useState(calling.is_set_apart);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setEditing(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  const updateSustainedDate = (newDate) => {
    setSustainedDate(newDate);
    setEditing(false);
    onUpdate({ ...calling, sustained_date: newDate });
  };

  const updateIsSetApart = () => {
    setIsSetApart(!isSetApart);
    onUpdate({ ...calling, is_set_apart: !isSetApart });
  };

  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  let memberPhotoSrc = calling.member_photo;
  let householdPhotoSrc = calling.household_photo;

  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#2684FC"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "150px",
              width: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {calling.notes?.length > 0 && (
              <Tippy content={calling.notes.map(n => n.note).join(" • ")} theme="lcrplus">
                <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
              </Tippy>
            )}
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(calling.recommended_name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)}>
            {formatName(calling.recommended_name)}
          </div>
          <div style={{ fontSize: "13px", fontStyle: "italic" }}>
            {calling.name}
          </div>
        </div>
        {showDetails && <MemberDetails member={calling} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `3px solid ${defaultBorderColor ? "#B0B5C0": "#2684FC"}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "-1px", right: "-7px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "22px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              <DropdownMenuRadioItem
                className='dropdown-item'
                onClick={() => deletePendingCalling(calling.id)}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-trash" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar" : "Delete"}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        {/* Photo section */}
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {calling.notes?.length > 0 && (
            <Tippy content={calling.notes.map(n => n.note).join(" • ")} theme="lcrplus">
              <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
            </Tippy>
          )}
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(calling.recommended_name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        
        {/* Name and calling section */}
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
          }}
        >
          <strong>{formatName(calling.recommended_name)}</strong>
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            fontStyle: "italic",
          }}
        >
          {calling.name}
        </div>
        
        {/* Sustained and Set Apart section */}
        <div style={{ fontSize: "14px", display: "flex", gap: "8px" }}>          
          <div style={{ fontSize: "14px", position: "relative" }}>
            <div>
              <strong>{unitDetails.language === "es" ? "Sostenido:" : "Sustained:"}</strong>
            </div>
            <div>
              <span
                onClick={() => setEditing(true)}
                style={{
                  cursor: "pointer",
                  textDecoration: !sustainedDate ? "underline" : "none",
                }}
              >
                {sustainedDate ? format(sustainedDate, "MM/dd/yy") : <i className="bx bx-calendar-edit" style={{ fontSize: "18px" }}></i>}
              </span>
              {editing && (
                <div
                  ref={calendarRef}
                  style={{ position: "absolute", zIndex: 9999, left: "-38.5px" }}
                >
                  <DayPicker
                    mode="single"
                    selected={sustainedDate}
                    onSelect={updateSustainedDate}
                    styles={{
                      day: {
                        width: '32px',
                        height: '32px',
                        fontSize: '14px',
                        padding: '2px',
                        lineHeight: '32px',
                        textAlign: 'center',
                      },
                    }}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #B0B5C0",
                      borderRadius: "4px",
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      zIndex: 9999,
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>

          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Apartado:" : "Set apart:"}</strong>
            </div>
            <div>
              <i 
                className={isSetApart ? "bx bx-checkbox-checked" : "bx bx-checkbox"} 
                style={{ fontSize: "23px", cursor: "pointer" }} 
                onClick={updateIsSetApart}
              ></i>
            </div>
          </div>
        </div>
      </div>
      {showDetails && <MemberDetails member={calling} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </>
  );
};