import { useState, useRef, useMemo } from "react";
import { Table } from "./Table";
import { formatName, formatEnglishBirthdate, formatSpanishBirthdate, formatEnglishDuration, formatSpanishDuration } from "./utils";
import Select, { components } from "react-select";
import { Popover } from '@mui/material';
import { NoProfilePicture } from "./NoProfilePicture";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import { Menu, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import * as Tabs from '@radix-ui/react-tabs';
import { MemberReportCard } from "./MemberReportCard";
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';

export const MemberReports = ({ sessionInfo, unitDetails, setMembersList, membersList }) => {
  const initialLoad = useRef(true);
  const [report, setReport] = useState({});
  const [columns, setColumns] = useState([]);
  const [memberReports, setMemberReports] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const [nameFormat, setNameFormat] = useState(sessionInfo.nameFormat);
  const [timeInWard, setTimeInWard] = useState(null);
  const [tithingDeclarationStatus, setTithingDeclarationStatus] = useState(null);
  const tithingDeclarationOptions = [
    { value: "FULL", label: `${unitDetails.language === "es" ? "Completo" : "Full"} (${membersList.filter(m => m.tithing_declaration_status === "FULL").length})` },
    { value: "PART", label: `${unitDetails.language === "es" ? "Parcial" : "Part"} (${membersList.filter(m => m.tithing_declaration_status === "PART").length})` },
    { value: "NON", label: `${unitDetails.language === "es" ? "No" : "Non"} (${membersList.filter(m => m.tithing_declaration_status === "NON").length})` },
    { value: "UNDECLARED", label: `${unitDetails.language === "es" ? "No declarado" : "Undeclared"} (${membersList.filter(m => !m.tithing_declaration_status).length})` }
  ];
  const timeInWardOptions = [
    { value: "one_week", label: `${unitDetails.language === "es" ? "1 semana" : "1 week"} (${membersList.filter(m => {
      if (!m.moved_in_date) return false;
      const daysSinceMovedIn = Math.floor((new Date() - new Date(m.moved_in_date)) / (1000 * 60 * 60 * 24));
      return daysSinceMovedIn <= 7;
    }).length})` },
    { value: "one_month", label: `${unitDetails.language === "es" ? "1 mes" : "1 month"} (${membersList.filter(m => {
      if (!m.moved_in_date) return false;
      const daysSinceMovedIn = Math.floor((new Date() - new Date(m.moved_in_date)) / (1000 * 60 * 60 * 24));
      return daysSinceMovedIn <= 30;
    }).length})` },
    { value: "three_months", label: `${unitDetails.language === "es" ? "3 meses" : "3 months"} (${membersList.filter(m => {
      if (!m.moved_in_date) return false;
      const daysSinceMovedIn = Math.floor((new Date() - new Date(m.moved_in_date)) / (1000 * 60 * 60 * 24));
      return daysSinceMovedIn <= 90;
    }).length})` },
    { value: "six_months", label: `${unitDetails.language === "es" ? "6 meses" : "6 months"} (${membersList.filter(m => {
      if (!m.moved_in_date) return false;
      const daysSinceMovedIn = Math.floor((new Date() - new Date(m.moved_in_date)) / (1000 * 60 * 60 * 24));
      return daysSinceMovedIn <= 180;
    }).length})` },
    { value: "one_year", label: `${unitDetails.language === "es" ? "1 año" : "1 year"} (${membersList.filter(m => {
      if (!m.moved_in_date) return false;
      const daysSinceMovedIn = Math.floor((new Date() - new Date(m.moved_in_date)) / (1000 * 60 * 60 * 24));
      return daysSinceMovedIn <= 365;
    }).length})` },
  ];
  const timeInWardThresholds = {
    one_week: 7,
    one_month: 30,
    three_months: 90,
    six_months: 180,
    one_year: 365
  };
  const [expiringRecommend, setExpiringRecommend] = useState(null);
  const expiringRecommendOptions = [
    { value: "this_month", label: `${unitDetails.language === "es" ? "Este mes" : "This month"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const now = new Date();
      const lastDayCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return expDate >= now && expDate <= lastDayCurrentMonth;
    }).length})` },
    { value: "next_month", label: `${unitDetails.language === "es" ? "Próximo mes" : "Next month"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const firstDayNextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
      const lastDayNextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0);
      return expDate >= firstDayNextMonth && expDate <= lastDayNextMonth;
    }).length})` },
    { value: "next_three_months", label: `${unitDetails.language === "es" ? "Próximos 3 meses" : "Next 3 months"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const now = new Date();
      const threeMonths = new Date();
      threeMonths.setMonth(now.getMonth() + 3);
      return expDate >= now && expDate <= threeMonths;
    }).length})` },
  ];
  const expiringRecommendThresholds = {
    this_month: {start: 0, end: 31},
    next_month: {start: 31, end: 62},
    next_three_months: {start: 0, end: 93},
  };
  const [expiredRecommend, setExpiredRecommend] = useState(null);
  const expiredRecommendOptions = [
    { value: "last_month", label: `${unitDetails.language === "es" ? "Mes pasado" : "Last month"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const now = new Date();
      const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      return expDate >= firstDayLastMonth && expDate <= lastDayLastMonth;
    }).length})` },
    { value: "last_three_months", label: `${unitDetails.language === "es" ? "Últimos 3 meses" : "Last 3 months"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const now = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(now.getMonth() - 3);
      return expDate >= threeMonthsAgo && expDate <= now;
    }).length})` },
    { value: "last_six_months", label: `${unitDetails.language === "es" ? "Últimos 6 meses" : "Last 6 months"} (${membersList.filter(m => {
      if (!m.temple_recommend_expiration_date) return false;
      const expDate = new Date(m.temple_recommend_expiration_date);
      const now = new Date();
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(now.getMonth() - 6);
      return expDate >= sixMonthsAgo && expDate <= now;
    }).length})` },
  ];
  const expiredRecommendThresholds = {
    last_month: {start: -31, end: -1},
    last_three_months: {start: -93, end: -1},
    last_six_months: {start: -186, end: -1}
  };
  const [birthdayRange, setBirthdayRange] = useState(null);
  const birthdayRangeOptions = [
    { value: "last_month", label: `${unitDetails.language === "es" ? "Mes pasado" : "Last month"} (${membersList.filter(m => {
      const now = new Date();
      const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      const birthday = new Date(now.getFullYear(), new Date(m.birthdate).getMonth(), new Date(m.birthdate).getDate());
      return birthday >= firstDayLastMonth && birthday <= lastDayLastMonth;
    }).length})` },
    { value: "this_month", label: `${unitDetails.language === "es" ? "Este mes" : "This month"} (${membersList.filter(m => {
      const now = new Date();
      const firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDayThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const birthday = new Date(now.getFullYear(), new Date(m.birthdate).getMonth(), new Date(m.birthdate).getDate());
      return birthday >= firstDayThisMonth && birthday <= lastDayThisMonth;
    }).length})` },
    { value: "next_month", label: `${unitDetails.language === "es" ? "Próximo mes" : "Next month"} (${membersList.filter(m => {
      const now = new Date();
      const nextMonth = now.getMonth() === 11 ? 0 : now.getMonth() + 1;
      const nextMonthYear = now.getMonth() === 11 ? now.getFullYear() + 1 : now.getFullYear();
      const firstDayNextMonth = new Date(nextMonthYear, nextMonth, 1);
      const lastDayNextMonth = new Date(nextMonthYear, nextMonth + 1, 0);
      const birthday = new Date(nextMonthYear, new Date(m.birthdate).getMonth(), new Date(m.birthdate).getDate());
      return birthday >= firstDayNextMonth && birthday <= lastDayNextMonth;
    }).length})` }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayType, setDisplayType] = useState('list');
  const [isColumnFilterOpen, setIsColumnFilterOpen] = useState(false);
  const [deselectedColumns, setDeselectedColumns] = useState([]);
  const [columnFilterAnchorEl, setColumnFilterAnchorEl] = useState(null);
  const [columnOptions, setColumnOptions] = useState([]);

  const selectedColumnOptions = columnOptions.filter(
    option => !deselectedColumns.includes(option.value)
  );

  const handleColumnFilterClick = (event) => {
    setColumnFilterAnchorEl(event.currentTarget);
    setIsColumnFilterOpen(true);
  };

  const handleColumnFilterClose = () => {
    setColumnFilterAnchorEl(null);
    setIsColumnFilterOpen(false);
  };

  const handleColumnSelectionChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    const newDeselectedColumns = columnOptions
      .filter(option => !selectedValues.includes(option.value))
      .map(option => option.value);
    setDeselectedColumns(newDeselectedColumns);
    updateMemberReportDeselectedColumns(newDeselectedColumns);
  };

  async function updateMemberReportDeselectedColumns(newDeselectedColumns) {
    try {
      await fetch("/api/update_member_report_deselected_columns", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ columnKeys: newDeselectedColumns }),
      });
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  const changeSort = (colId) => {
    if (colId == sortColumn) {
      const nextDir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(nextDir);
    } else {
      setSortColumn(colId);
      setSortDirection("asc");
    }
  };

  async function fetchMemberReports() {
    try {
      const res = await fetch("/api/list_member_reports")
      const data = await res.json();
      const reportsWithCounts = data.map(report => {
        const filteredMembers = membersList.filter(member => {
          return report.filters.every(filter => {
            const memberValue = member[filter.column_key];

            if (filter.column_key === "mission_country") {
              return memberValue != null;
            }

            if (filter.column_value === null) {
              return memberValue === null || memberValue === undefined;
            }

            const filterValue = filter.column_type === "boolean" 
              ? filter.column_value.toLowerCase() === "true"
              : filter.column_value;
            return memberValue === filterValue;
          });
        });

        return {
          ...report,
          label: ["recent_move_ins", "expiring_temple_recommends", "expired_temple_recommends", "birthdays", "tithing_declaration"].includes(report.value) ? report.label : `${report.label} (${filteredMembers.length})`
        };
      });
      setTimeInWard(timeInWardOptions.find(option => option.value === "one_month"));
      setExpiringRecommend(expiringRecommendOptions.find(option => option.value === "this_month"));
      setExpiredRecommend(expiredRecommendOptions.find(option => option.value === "last_month"));
      setBirthdayRange(birthdayRangeOptions.find(option => option.value === "this_month"));
      setTithingDeclarationStatus(tithingDeclarationOptions.find(option => option.value === "UNDECLARED"));
      setMemberReports(reportsWithCounts);
      if (reportsWithCounts.length > 0) {
        setReport(reportsWithCounts[0]);
        setColumns(reportsWithCounts[0].columns);
        setColumnOptions(columnDetails
          .filter(col => reportsWithCounts[0].columns.includes(col.id) && col.id !== 'name')
          .map(col => ({
            value: col.id,
            label: col.label
          })));
        setSortColumn(reportsWithCounts[0].default_sort_column);
        setSortDirection(reportsWithCounts[0].default_sort_direction);
      }
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function fetchLabels() {
    const res = await fetch("/api/list_labels");
    const data = await res.json();
    setLabels(data);
  }

  async function fetchMemberReportDeselectedColumns() {
    const res = await fetch("/api/list_member_report_deselected_columns");
    const data = await res.json();
    setDeselectedColumns(data);
  }

  async function createMemberLabel(memberId, labelId) {
    try {
      const res = await fetch("/api/create_member_label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId, labelId }),
      });
      await res.json();
      setMembersList((prevMembers) =>
        prevMembers.map((item) => 
          item.member_id === memberId 
            ? { ...item, label_id: labelId }
            : item
        )
      );
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  if (initialLoad.current && membersList.length > 0) {
    const loadData = async () => {
      await fetchMemberReports();
      await fetchLabels();
      await fetchMemberReportDeselectedColumns();
    };

    loadData();
    initialLoad.current = false;
  }

  const onScroll = (e) => {
    const isAtLeftEdge = e.target.scrollLeft < 1;
    setIsScrolledToLeft(isAtLeftEdge);

    const isAtRightEdge =
      Math.abs(
        e.target.scrollWidth - e.target.clientWidth - e.target.scrollLeft,
      ) < 1;
    setIsScrolledToRight(isAtRightEdge);
  };

  const reportSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      width: "252px",
      textAlign: "left",
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
      fontWeight: 600
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "252px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 999,
      textAlign: "left",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
      textAlign: "left",
      color: "black",
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '8px',
        display: 'block'
      },
      '&::-webkit-scrollbar-track': {
        background: '#F6F7F8'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#99A0AE',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#525866'
      }
    }),
    container: (provided) => ({
      ...provided,
      width: "252px",
      position: "relative",
      zIndex: 999,
      textAlign: "left",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px"
    })
  };

  const timeRangeSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      width: "200px",
      textAlign: "left",
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "200px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 999,
      textAlign: "left",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
      textAlign: "left",
      color: "black",
    }),
    container: (provided) => ({
      ...provided,
      width: "200px",
      position: "relative",
      zIndex: 999,
      textAlign: "left",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px"
    })
  };

  const columnDetails = [
    {
      id: "name",
      label: unitDetails.language === "es" ? "Nombre" : "Name",
      width: "275px",
      formatter: (row) => {
        const handleClick = (e) => {
          e.stopPropagation();
          setActiveDropdownId(row.member_id);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setActiveDropdownId(null);
          setAnchorEl(null);
        };

        const handleLabelSelect = (labelId, e) => {
          e.stopPropagation();
          createMemberLabel(row.member_id, labelId);
          handleClose();
        };

        return <div 
          style={{ 
            display: "flex", 
            alignItems: "center", 
            fontSize: '14px', 
            gap: "10px",
            position: "relative"
          }}
          className="name-cell"
        >
          {(row.member_photo || row.household_photo) ?
            <img
              src={row.member_photo || row.household_photo}
              alt={formatName(row.name)}
              style={{ width: "35px", height: "35px", borderRadius: "50%", border: "0.5px solid #B0B5C0", objectFit: "cover" }}
            /> : <NoProfilePicture styles={{ width: "35px", height: "35px" }} />
          }
          <span style={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} cursor="pointer">{nameFormat === "first_last" ? formatName(row.name) : row.name}</span>
          {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) && (
            row.label_id ?
              <Tippy 
                content={unitDetails.language === "es" ? "Cambiar nivel de actividad" : "Change activity level"} 
                theme="lcrplus"
                delay={[400, 0]}
              >
                <span 
                  onClick={handleClick}
                  style={{ 
                    fontSize: "12px",
                    fontWeight: "600", 
                    color: labels.find(label => label.id === row.label_id).dark_hex, 
                    border: `1px solid ${labels.find(label => label.id === row.label_id).dark_hex}`, 
                    padding: "2px 6px", 
                    borderRadius: "4px", 
                    position: "absolute", 
                    right: "4px",
                    cursor: "pointer"
                  }}
                >
                  {labels.find(label => label.id === row.label_id).name}
                </span>
              </Tippy> : 
              <Tippy
                content={unitDetails.language === "es" ? "Establecer nivel de actividad" : "Set activity level"}
                theme="lcrplus"
                delay={[400, 0]}
              >
                <LocalOfferOutlinedIcon 
                  onClick={handleClick}
                  style={{ 
                    position: "absolute",
                    right: "0px",
                    display: activeDropdownId === row.member_id ? "block" : "none",
                    color: "#6b7280",
                    cursor: "pointer",
                    fontSize: "16px"
                  }}
                  className="tag-icon"
                />
              </Tippy>
            )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && activeDropdownId === row.member_id}
            onClose={handleClose}
          >
            {labels.map(label => (
              <MenuItem 
                key={label.id}
                onClick={(e) => handleLabelSelect(label.id, e)}
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
                selected={row.label_id === label.id}
              >
                {label.name}
                <CircleIcon sx={{fontSize: 14, color: label.dark_hex}} />
              </MenuItem>
            ))}
          </Menu>
        </div>;
      },
      sortFn: (row) => {
        return nameFormat === "first_last" ? formatName(row.name) : row.name;
      }
    },
    {
      id: "gender", 
      label: unitDetails.language === "es" ? "Género" : "Gender",
      width: "100px",
      formatter: (row) => {
        return row.gender;
      },
      sortFn: (row) => {
        return row.gender;
      }
    },
    {
      id: "unit_name", 
      label: unitDetails.language === "es" ? "Unidad" : "Unit",
      width: "200px",
      formatter: (row) => {
        return row.unit_name;
      },
      sortFn: (row) => {
        return row.unit_name;
      }
    },
    {
      id: "tithing_declaration_status", 
      label: unitDetails.language === "es" ? "Estado" : "Status",
      width: "100px",
      formatter: (row) => {
        return row.tithing_declaration_status ? row.tithing_declaration_status : "-";
      },
      sortFn: (row) => {
        return row.tithing_declaration_status;
      }
    },
    {
      id: "tithing_declaration_declared_by", 
      label: unitDetails.language === "es" ? "Declarado por" : "Declared by",
      width: "100px",
      formatter: (row) => {
        return row.tithing_declaration_declared_by;
      },
      sortFn: (row) => {
        return row.tithing_declaration_declared_by;
      }
    },
    {
      id: "tithing_amount", 
      label: unitDetails.language === "es" ? "Diezmo" : "Tithing",
      width: "100px",
      formatter: (row) => {
        return row.tithing_amount ? `$${row.tithing_amount}` : '-';
      },
      sortFn: (row) => {
        return row.tithing_amount;
      }
    },
    {
      id: "fast_offering_amount", 
      label: unitDetails.language === "es" ? "Ofrenda de ayuno" : "Fast offering",
      width: "100px",
      formatter: (row) => {
        return row.fast_offering_amount ? `$${row.fast_offering_amount}` : '-';
      },
      sortFn: (row) => {
        return row.fast_offering_amount;
      }
    },
    {
      id: "temple_recommend_status",
      label: unitDetails.language === "es" ? "Estado de recomendación" : "Recommend status", 
      width: "150px",
      formatter: (row) => {
        return row.temple_recommend_status ? row.temple_recommend_status.charAt(0).toUpperCase() + row.temple_recommend_status.slice(1).toLowerCase() : "-";
      },
      sortFn: (row) => {
        return row.temple_recommend_status;
      }
    },
    {
      id: "temple_recommend_expiration_date",
      label: unitDetails.language === "es" ? "Vencimiento de recomendación" : "Recommend expiration", 
      width: "200px",
      formatter: (row) => {
        return row.temple_recommend_expiration_date ? (unitDetails.language === "es" ? formatSpanishBirthdate(row.temple_recommend_expiration_date) : formatEnglishBirthdate(row.temple_recommend_expiration_date)) : "-";
      },
      sortFn: (row) => {
        return row.temple_recommend_expiration_date;
      }
    },
    {
      id: "endowment_date",
      label: unitDetails.language === "es" ? "Fecha de investidura" : "Endowment date", 
      width: "200px",
      formatter: (row) => {
        return row.endowment_date ? (unitDetails.language === "es" ? formatSpanishBirthdate(row.endowment_date) : formatEnglishBirthdate(row.endowment_date)) : "-";
      },
      sortFn: (row) => {
        return row.endowment_date;
      }
    },
    {
      id: "phone_number",
      label: unitDetails.language === "es" ? "Número de teléfono" : "Phone number", 
      width: "150px",
      formatter: (row) => {
        return row.phone_number ? row.phone_number : "-";
      },
      sortFn: (row) => {
        return row.phone_number;
      }
    },
    {
      id: "email",
      label: unitDetails.language === "es" ? "Correo electrónico" : "Email", 
      width: "250px",
      formatter: (row) => {
        return <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {row.email ? row.email : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.email;
      }
    },
    {
      id: "address_line_1",
      label: unitDetails.language === "es" ? "Dirección" : "Address",
      width: "300px",
      formatter: (row) => {
        return <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {row.address_line_1 ? [row.address_line_1, row.address_line_2, row.address_line_3].filter(Boolean).join(", ") : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.address_line_1;
      }
    },
    {
      id: "latitude",
      label: unitDetails.language === "es" ? "Latitud" : "Latitude",
      width: "100px",
      formatter: (row) => {
        return <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {row.latitude ? row.latitude : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.latitude;
      }
    },
    {
      id: "longitude",
      label: unitDetails.language === "es" ? "Longitud" : "Longitude",
      width: "100px",
      formatter: (row) => {
        return <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {row.longitude ? row.longitude : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.longitude;
      }
    },
    {
      id: "callings", 
      label: unitDetails.language === "es" ? "Llamamientos" : "Calling(s)",
      width: "300px",
      formatter: (row) => {
        return <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {row.callings ? row.callings : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.callings;
      }
    },
    {
      id: "sustained_dates", 
      label: unitDetails.language === "es" ? "Sostenido" : "Sustained",
      width: "150px",
      formatter: (row) => {
        return row.sustained_dates ? row.sustained_dates : "-";
      },
      sortFn: (row) => {
        return row.sustained_dates;
      }
    },
    {
      id: "priesthood_office",
      label: unitDetails.language === "es" ? "Oficio del sacerdocio" : "Priesthood office", 
      width: "150px",
      formatter: (row) => {
        return row.priesthood_office ? row.priesthood_office : "-";
      },
      sortFn: (row) => {
        return row.priesthood_office;
      }
    },
    {
      id: "is_prospective_elder",
      label: unitDetails.language === "es" ? "Élder prospectivo" : "Prospective elder", 
      width: "150px",
      formatter: (row) => {
        return row.is_prospective_elder !== null ? (row.is_prospective_elder ? (unitDetails.language === "es" ? "Sí" : "Yes") : "No") : "-";
      },
      sortFn: (row) => {
        return row.is_prospective_elder;
      }
    },
    {
      id: "age",
      label: unitDetails.language === "es" ? "Edad" : "Age", 
      width: "100px",
      formatter: (row) => {
        return row.age ? row.age : "-";
      },
      sortFn: (row) => {
        return row.age;
      }
    },
    {
      id: "attendance",
      label: 
        <Tippy content={unitDetails.language === "es" ? "Porcentaje de domingos asistidos en los últimos 3 meses" : "Percent of Sundays attended in the last 3 months"} theme="lcrplus">
          <span style={{ borderBottom: "1px dotted #6b7280" }}>
            {unitDetails.language === "es" ? "Asistencia" : "Attendance"}
          </span>
        </Tippy>,
      width: "150px",
      formatter: (row) => {
        if (!row.attendance) return "-";
        return `${row.attendance % 1 === 0 ? Math.floor(row.attendance) : row.attendance}%`;
      },
      sortFn: (row) => {
        return Number(row.attendance || 0);
      }
    },
    {
      id: "moved_in_date",
      label: unitDetails.language === "es" ? "Fecha de mudanza" : "Move-in date", 
      width: "150px",
      formatter: (row) => {
        return row.moved_in_date ? formatEnglishBirthdate(row.moved_in_date) : "-";
      },
      sortFn: (row) => {
        return row.moved_in_date;
      }
    },
    {
      id: "time_in_ward",
      label: unitDetails.language === "es" ? "Tiempo en el barrio" : "Time in ward", 
      width: "150px",
      formatter: (row) => {
        return row.time_in_ward ? formatEnglishDuration(row.time_in_ward) : "-";
      },
      sortFn: (row) => {
        return row.time_in_ward;
      }
    },
    {
      id: "notes",
      label: unitDetails.language === "es" ? "Notas" : "Notes", 
      width: "250px",
      formatter: (row) => {
        return <div style={{ 
          maxHeight: "36px",
          overflowY: "auto",
          fontSize: "12px",
          wordWrap: "break-word",
          whiteSpace: "normal"
        }}>
          {row.notes.length > 0 ? row.notes.map(n => n.note).join(" • ") : "-"}
        </div>;
      },
      sortFn: (row) => {
        return row.notes.length > 0 ? row.notes[0].note : "";
      }
    },
    {
      id: "has_patriarchal_blessing",
      label: unitDetails.language === "es" ? "Bendición patriarcal" : "Patriarchal blessing", 
      width: "200px",
      formatter: (row) => {
        return row.has_patriarchal_blessing !== null ? (row.has_patriarchal_blessing ? (unitDetails.language === "es" ? "Sí" : "Yes") : "No") : "-";
      },
      sortFn: (row) => {
        return row.has_patriarchal_blessing;
      }
    },
    {
      id: "is_convert",
      label: unitDetails.language === "es" ? "Converso" : "Convert", 
      width: "200px",
      formatter: (row) => {
        return row.is_convert !== null ? (row.is_convert ? (unitDetails.language === "es" ? "Sí" : "Yes") : "No") : "-";
      },
      sortFn: (row) => {
        return row.is_convert;
      }
    },
    {
      id: "is_sealed",
      label: unitDetails.language === "es" ? "Matrimonio anterior" : "Previous marriage",
      width: "150px",
      formatter: (row) => {
        return row.is_sealed !== null ? (row.is_sealed ? (unitDetails.language === "es" ? "Sí" : "Yes") : "No") : "-";
      },
      sortFn: (row) => {
        return row.is_sealed;
      }
    },
    {
      id: "birthdate",
      label: unitDetails.language === "es" ? "Fecha de nacimiento" : "Birthdate", 
      width: "150px",
      formatter: (row) => {
        return row.birthdate ? formatEnglishBirthdate(row.birthdate) : "-";
      },
      sortFn: (row) => {
        return row.birthdate;
      }
    },
    {
      id: "birth_place",
      label: unitDetails.language === "es" ? "Lugar de nacimiento" : "Birthplace", 
      width: "250px",
      formatter: (row) => {
        return row.birth_place ? row.birth_place : "-";
      },
      sortFn: (row) => {
        return row.birth_place;
      }
    },
    {
      id: "mission_country",
      label: unitDetails.language === "es" ? "País de la misión" : "Mission country", 
      width: "200px",
      formatter: (row) => {
        return row.mission_country ? row.mission_country : "-";
      },
      sortFn: (row) => {
        return row.mission_country;
      }
    },
    {
      id: "mission_language",
      label: unitDetails.language === "es" ? "Idioma de la misión" : "Mission language", 
      width: "150px",
      formatter: (row) => {
        return row.mission_language ? row.mission_language : "-";
      },
      sortFn: (row) => {
        return row.mission_language;
      }
    },
  ];

  const handleReportChange = (report) => {
    setReport(report);
    setColumns(memberReports.find(r => r.value === report.value).columns);
    setColumnOptions(columnDetails
      .filter(col => 
        report.columns.includes(col.id) && 
        col.id !== 'name'
      )
      .map(col => ({
        value: col.id,
        label: col.label
      })));
    setSortColumn(report.default_sort_column);
    setSortDirection(report.default_sort_direction);
  };

  const filteredMembers = useMemo(() => {
    if (!report.filters) return membersList;
    
    let filtered = membersList.filter(member => {
      return report.filters.every(filter => {
        const memberValue = member[filter.column_key];

        if (filter.column_key === "mission_country") {
          return memberValue != null;
        }

        if (filter.column_value === null) {
          return memberValue === null || memberValue === undefined;
        }

        const filterValue = filter.column_type === "boolean" 
          ? filter.column_value.toLowerCase() === "true"
          : filter.column_value;
        return memberValue === filterValue;
      });
    });

    if (report.value === "recent_move_ins") {
      const daysThreshold = timeInWardThresholds[timeInWard.value];
      filtered = filtered.filter(member => {
        if (!member.moved_in_date) return false;
        const daysSinceMovedIn = Math.floor((new Date() - new Date(member.moved_in_date)) / (1000 * 60 * 60 * 24));
        return daysSinceMovedIn <= daysThreshold;
      });
    }

    if (report.value === "expiring_temple_recommends") {
      const thresholds = expiringRecommendThresholds[expiringRecommend.value];
      filtered = filtered.filter(member => {
        if (!member.temple_recommend_expiration_date) return false;
        const daysUntilExpiration = Math.floor((new Date(member.temple_recommend_expiration_date) - new Date()) / (1000 * 60 * 60 * 24));
        return daysUntilExpiration >= thresholds.start && daysUntilExpiration <= thresholds.end;
      });
    }

    if (report.value === "expired_temple_recommends") {
      const thresholds = expiredRecommendThresholds[expiredRecommend.value];
      filtered = filtered.filter(member => {
        if (!member.temple_recommend_expiration_date) return false;
        const daysUntilExpiration = Math.floor((new Date(member.temple_recommend_expiration_date) - new Date()) / (1000 * 60 * 60 * 24));
        return daysUntilExpiration >= thresholds.start && daysUntilExpiration <= thresholds.end;
      });
    }

    if (report.value === "birthdays") {
      filtered = filtered.filter(member => {
        if (!member.birthdate) return false;
        const now = new Date();
        const birthday = new Date(member.birthdate);
        if (birthdayRange.value === "this_month") {
          return birthday.getMonth() === now.getMonth();
        } else if (birthdayRange.value === "next_month") {
          const nextMonth = now.getMonth() === 11 ? 0 : now.getMonth() + 1;
          return birthday.getMonth() === nextMonth;
        } else if (birthdayRange.value === "last_month") {
          const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
          return birthday.getMonth() === lastMonth;
        }
        return false;
      });
    }

    if (report.value === "tithing_declaration") {
      filtered = filtered.filter(member => {
        return tithingDeclarationStatus.value === "UNDECLARED" ? !member.tithing_declaration_status : member.tithing_declaration_status === tithingDeclarationStatus.value;
      });
    }

    if (searchTerm) {
      filtered = filtered.filter(member => 
        formatName(member.name).toLowerCase().includes(searchTerm.toLowerCase()) || 
        member.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  }, [membersList, report, searchTerm, timeInWard, timeInWardThresholds, expiringRecommend, expiringRecommendThresholds, expiredRecommend, expiredRecommendThresholds, birthdayRange, tithingDeclarationStatus]);

  const downloadCSV = () => {
    const visibleColumns = columnDetails.filter(col => columns.includes(col.id));
    
    // Create CSV header
    const header = visibleColumns.map(col => {
      if (col.id === 'attendance') return 'Attendance';
      return col.label;
    }).join(',');
    
    // Create CSV rows
    const rows = filteredMembers.map(member => {
      return visibleColumns.map(col => {
        let value = member[col.id];
        if (col.id === 'name') value = nameFormat === "first_last" ? formatName(value) : value;
        if (col.id === 'birthdate' || col.id === 'moved_in_date' || col.id === 'temple_recommend_expiration_date') value = value ? formatEnglishBirthdate(value) : '';
        if (col.id === 'time_in_ward') value = value ? formatEnglishDuration(value) : '';
        if (col.id === 'is_prospective_elder') value = value !== null ? (value ? 'Yes' : 'No') : '';
        // Escape commas and quotes in the value
        if (value === null || value === undefined) value = '';
        value = value.toString().replace(/"/g, '""');
        if (value.includes(',')) value = `"${value}"`;
        return value;
      }).join(',');
    });

    // Combine header and rows
    const csv = [header, ...rows].join('\n');
    
    // Create and trigger download
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      let filename = `${report.label.split(' (')[0]}.csv`;

      if (report.value === "recent_move_ins") {
        filename = `${report.label.split(' (')[0]} - ${timeInWard.label.split(' (')[0]}.csv`;
      } else if (report.value === "expiring_temple_recommends") {
        filename = `${report.label.split(' (')[0]} - ${expiringRecommend.label.split(' (')[0]}.csv`;
      } else if (report.value === "expired_temple_recommends") {
        filename = `${report.label.split(' (')[0]} - ${expiredRecommend.label.split(' (')[0]}.csv`;
      } else if (report.value === "birthdays") {
        filename = `${report.label.split(' (')[0]} - ${birthdayRange.label.split(' (')[0]}.csv`;
      }

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => {}}
          />
          {children}
        </div>
      </components.Option>
    );
  };
  
  return (memberReports.length > 0 && membersList.length > 0 && labels.length > 0 && ( 
    <>
      <div style={{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "16px",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column"
      }}>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          position: "relative",
          zIndex: 999,
          paddingBottom: "16px",
        }}>
          <Select
            value={report}
            onChange={handleReportChange}
            options={memberReports}
            styles={reportSelectStyles}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            {report.value === "recent_move_ins" && (
              <Select
                value={timeInWard}
                onChange={setTimeInWard}
                options={timeInWardOptions}
                styles={timeRangeSelectStyles}
              />
            )}
            {report.value === "expiring_temple_recommends" && (
              <Select
                value={expiringRecommend}
                onChange={setExpiringRecommend}
                options={expiringRecommendOptions}
                styles={timeRangeSelectStyles}
              />
            )}
            {report.value === "expired_temple_recommends" && (
              <Select
                value={expiredRecommend}
                onChange={setExpiredRecommend}
                options={expiredRecommendOptions}
                styles={timeRangeSelectStyles}
              />
            )}
            {report.value === "birthdays" && (
              <Select
                value={birthdayRange}
                onChange={setBirthdayRange}
                options={birthdayRangeOptions}
                styles={timeRangeSelectStyles}
              />
            )}
            {report.value === "tithing_declaration" && (
              <Select
                value={tithingDeclarationStatus}
                onChange={setTithingDeclarationStatus}
                options={tithingDeclarationOptions}
                styles={timeRangeSelectStyles}
              />
            )}
            <Tabs.Root defaultValue={displayType} style={{ display: 'flex', gap: '8px' }}>
              <Tabs.List style={{ display: 'flex', backgroundColor: "#F6F7F8", borderRadius: '6px', height: "36px" }}>
                <Tabs.Trigger
                  value={'list'}
                  onClick={() => setDisplayType('list')}
                  style={{
                    border: 'none',
                    borderRadius: '4px',
                    color: displayType === 'list' ? 'black' : '#99A0AE',
                    backgroundColor: displayType === 'list' ? 'white' : '#F6F7F8',
                    cursor: 'pointer',
                    margin: '4px',
                    transition: 'background-color 0.2s',
                    fontSize: '14px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: displayType === 'list' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                  }}
                >
                  <TocRoundedIcon style={{ color: "#99A0AE", transform: "rotate(180deg)" }} />
                </Tabs.Trigger>
                <Tabs.Trigger
                  value={'grid'}
                  onClick={() => setDisplayType('grid')}
                  style={{
                    border: 'none',
                    borderRadius: '4px',
                    color: displayType === 'grid' ? 'black' : '#99A0AE',
                    backgroundColor: displayType === 'grid' ? 'white' : '#F6F7F8',
                    cursor: 'pointer',
                    margin: '4px',
                    transition: 'background-color 0.2s',
                    fontSize: '14px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: displayType === 'grid' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
                  }}
                >
                  <GridViewRoundedIcon style={{ color: "#99A0AE", fontSize: "20px" }} />
                </Tabs.Trigger>
              </Tabs.List>
            </Tabs.Root>
            <div style={{ position: "relative" }}>
              <SearchOutlinedIcon style={{ 
                position: "absolute",
                left: "12px",
                top: "44%",
                transform: "translateY(-50%)",
                color: "#6b7280",
                fontSize: "20px"
              }}/>
              <input
                type="text"
                placeholder="Search members"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  padding: "8px 12px 8px 38px",
                  fontSize: "14px", 
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "200px"
                }}
              />
            </div>
            <button
              onClick={handleColumnFilterClick}
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0px"
              }}
            >
              <ViewColumnRoundedIcon className='download-csv-button'/>
            </button>
            <Popover
              open={isColumnFilterOpen}
              anchorEl={columnFilterAnchorEl}
              onClose={handleColumnFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{ padding: '8px', width: '300px' }}>
                <Select
                  isMulti
                  options={columnOptions}
                  value={selectedColumnOptions}
                  onChange={handleColumnSelectionChange}
                  components={{ Option }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  menuIsOpen={true}
                  styles={{
                    control: () => ({
                      display: 'none'
                    }),
                    option: (base) => ({
                      ...base,
                      fontSize: '14px',
                      backgroundColor: 'transparent', 
                      color: 'black',
                      padding: '8px 12px',
                      ':hover': {
                        backgroundColor: '#f3f4f6'
                      }
                    }),
                    menu: (base) => ({
                      ...base,
                      boxShadow: 'none',
                      border: 'none', 
                      marginTop: '0',
                      position: 'static'
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '400px',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                        display: 'block'
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#F6F7F8'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#99A0AE',
                        borderRadius: '4px'
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        background: '#525866'
                      }
                    })
                  }}
                />
              </div>
            </Popover>
            <button
              onClick={downloadCSV}
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0px"
              }}
            >
              <FileDownloadOutlinedIcon className='download-csv-button'/>
            </button>
          </div>
        </div>

        {displayType === 'list' ? (
          <div style={{ 
            height: "100%",
            display: "flex",
            flexDirection: "column"
          }}>
          <Table
            sessionInfo={sessionInfo}
            unitDetails={unitDetails}
            setMembersList={setMembersList}
            membersList={filteredMembers}
            columnDetails={columnDetails.filter(col => (columns.includes(col.id) && !deselectedColumns.includes(col.id)) || col.id === sortColumn)}
            onScroll={onScroll}
            isScrolledToLeft={isScrolledToLeft}
            isScrolledToRight={isScrolledToRight}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            changeSort={changeSort}
            labels={labels}
            isLabelDropdownOpen={Boolean(anchorEl) && activeDropdownId}
            nameFormat={nameFormat}
            setNameFormat={setNameFormat}
            />
          </div>
        ) : (
          <div className="members">
            {filteredMembers.map(member => (
              <MemberReportCard
                key={member.id}
                member={member}
                sessionInfo={sessionInfo}
                unitDetails={unitDetails}
                membersList={membersList}
                setMembersList={setMembersList}
              />
            ))}
          </div>
        )}
      </div>
    </>
  ))
};
