import { useState } from "react";
import { MemberDetails } from "./MemberDetails";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Menu, MenuItem } from '@mui/material';

export const Table = ({
  sessionInfo,
  unitDetails,
  setMembersList,
  membersList,
  columnDetails,
  onScroll,
  isScrolledToLeft,
  isScrolledToRight,
  sortColumn,
  sortDirection,
  changeSort,
  labels,
  isLabelDropdownOpen,
  nameFormat,
  setNameFormat,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  async function updateNameFormat(newValue) {
    try {
      setNameFormat(newValue);
      await fetch("/api/update_name_format", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ nameFormat: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  return (
    <div style={{
      display: "flex", 
      flexDirection: "column",
      height: "100%",
      maxHeight: "calc(100vh - 180px)",
      overflow: "auto",
      color: "#0E121B",
      position: "relative",
      fontSize: "14px"
    }}>
      <div style={{
        flex: 1,
        overflowX: "auto",
        overflowY: "auto",
        position: "relative",
        borderRadius: "6px",
        border: "1px solid #e5e7eb"
      }} onScroll={onScroll}>
        <table style={{
          width: "100%",
          borderCollapse: "collapse",
          whiteSpace: "nowrap",
          position: "relative"
        }}>
          <thead>
            <tr style={{
              backgroundColor: "#f9fafb", 
              position: "sticky",
              top: 0,
              zIndex: 10
            }}>
              {columnDetails.map((column) => (
                <th
                  key={column.id}
                  onClick={(e) => {
                    if (e.target.closest('.gear-icon')) return;
                    changeSort(column.id);
                  }}
                  style={{
                    padding: "5px",
                    paddingLeft: "10px",
                    textAlign: "left", 
                    fontWeight: 600,
                    color: sortColumn === column.id ? "#374151" : "#6b7280",
                    cursor: "pointer",
                    position: column.id === "name" ? "sticky" : "static",
                    left: column.id === "name" ? 0 : "auto",
                    backgroundColor: "#f9fafb",
                    width: column.width,
                    minWidth: column.width,
                    maxWidth: column.width,
                    zIndex: column.id === "name" ? 20 : 10,
                    borderBottom: "1px solid #e5e7eb",
                    borderRight: column.id === "name" ? "1px solid #e5e7eb" : "none"
                  }}
                >
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    position: "relative",
                  }}>
                    {column.label}
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "12px"
                    }}>
                      <i
                        className="bx bx-caret-up"
                        style={{
                          color: sortColumn === column.id && sortDirection === "asc" ? "#6b7280" : "#d1d5db",
                          marginBottom: "-6px"
                        }}
                      />
                      <i
                        className="bx bx-caret-down"
                        style={{
                          color: sortColumn === column.id && sortDirection === "desc" ? "#6b7280" : "#d1d5db"
                        }}
                      />
                    </div>
                    {column.id === "name" && (
                      <div
                        className="gear-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                        style={{
                          marginLeft: "auto",
                          transition: "opacity 0.2s",
                          cursor: "pointer",
                          opacity: Boolean(anchorEl) ? 1 : 0,
                        }}
                      >
                        <SettingsOutlinedIcon style={{ color: "#6b7280", fontSize: "16px" }} />
                      </div>
                    )}
                    {column.id === "name" && (
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                          }}
                        >
                          <MenuItem 
                            key="first_last"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateNameFormat("first_last");
                              setAnchorEl(null);
                            }}
                            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
                            selected={nameFormat === "first_last"}
                          >
                            John Smith
                          </MenuItem>
                          <MenuItem 
                            key="last_first"
                            onClick={(e) => {
                              e.stopPropagation();
                              updateNameFormat("last_first");
                              setAnchorEl(null);
                            }}
                            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
                            selected={nameFormat === "last_first"}
                          >
                            Smith, John
                          </MenuItem>
                        </Menu>
                      )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {membersList.length === 0 ? (
              <tr>
                <td colSpan={columnDetails.length} style={{
                  textAlign: "center",
                  padding: "40px",
                  color: "#6b7280",
                  fontSize: "14px"
                }}>
                  {unitDetails.language === "es" ? "Sin resultados" : "No results"}
                </td>
              </tr>
            ) : (
              membersList
                .sort((a, b) => {
                  const column = columnDetails.find(col => col.id === sortColumn);
                  const aValue = column.sortFn({ [sortColumn]: a[sortColumn] });
                  const bValue = column.sortFn({ [sortColumn]: b[sortColumn] });

                  // Handle nulls
                  if (aValue === null || aValue === "") return 1;
                  if (bValue === null || bValue === "") return -1;

                  if (sortDirection === "asc") {
                    return aValue > bValue ? 1 : -1;
                  } else {
                    return aValue < bValue ? 1 : -1;
                  }
                })
                .map((row) => (
                <tr
                  key={row.id}
                  onClick={() => {
                    if (!isLabelDropdownOpen) {
                      setSelectedMember(row);
                      setShowDetails(true);
                    }
                  }}
                  style={{
                    borderBottom: "1px solid #e5e7eb",
                    cursor: "pointer"
                  }}
                >
                  {columnDetails.map((column) => (
                    <td
                      key={column.id}
                      style={{
                        padding: "5px",
                        paddingLeft: column.id !== "name" ? "10px" : "5px",
                        position: column.id === "name" ? "sticky" : "static",
                        left: column.id === "name" ? 0 : "auto",
                        width: column.width,
                        minWidth: column.width,
                        maxWidth: column.width,
                        zIndex: column.id === "name" ? 1 : 0,
                        textAlign: "left",
                        borderRight: column.id === "name" ? "1px solid #e5e7eb" : "none",
                        backgroundColor: row.label_id ? labels.find(label => label.id === row.label_id).light_hex : column.id === "name" ? "#F9FAFB" : "white"
                      }}
                    >
                      {column.formatter(row)}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        width: "10px",
        pointerEvents: "none",
        background: `linear-gradient(to right, transparent, ${isScrolledToRight ? "transparent" : "rgba(0,0,0,0.1)"})`,
        zIndex: 30
      }} />
      <div style={{
        position: "absolute",
        top: 0,
        left: 285,
        bottom: 0,
        width: "10px",
        pointerEvents: "none",
        background: `linear-gradient(to left, transparent, ${isScrolledToLeft ? "transparent" : "rgba(0,0,0,0.1)"})`,
        zIndex: 30
      }} />
      {showDetails && <MemberDetails member={selectedMember} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </div>
  );
};
