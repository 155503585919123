export const SkeletonFlashcard = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
      width: '300px'
    }}>
      {/* Mode tabs skeleton */}
      <div style={{
        width: '300px',
        height: '36px',
        backgroundColor: '#EEF0F4',
        borderRadius: '6px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div className="shimmer" />
      </div>

      {/* Stats skeleton */}
      <div style={{
        width: '300px',
        height: '24px',
        backgroundColor: '#EEF0F4',
        borderRadius: '6px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div className="shimmer" />
      </div>

      {/* Flashcard skeleton */}
      <div style={{
        width: '300px',
        height: '300px',
        backgroundColor: '#EEF0F4',
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div className="shimmer" />
      </div>

      {/* Buttons skeleton */}
      <div style={{
        width: '300px',
        height: '36px',
        backgroundColor: '#EEF0F4',
        borderRadius: '6px',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div className="shimmer" />
      </div>
    </div>
  );
};
