import { useRef, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import Select from "react-select";
import { NoProfilePicture } from './NoProfilePicture';
import { formatName } from './utils';
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

export const SundayProgram = ({ membersList, sustainings, releases, newMembers }) => {
  const initialLoad = useRef(true);
  const [hymns, setHymns] = useState([]);
  const [openingHymn, setOpeningHymn] = useState(null);
  const [sacramentHymn, setSacramentHymn] = useState(null);
  const [intermediateHymn, setIntermediateHymn] = useState(null);
  const [closingHymn, setClosingHymn] = useState(null);
  const [firstSpeaker, setFirstSpeaker] = useState(null);
  const [secondSpeaker, setSecondSpeaker] = useState(null);
  const [thirdSpeaker, setThirdSpeaker] = useState(null);
  const [openingPrayer, setOpeningPrayer] = useState(null);
  const [closingPrayer, setClosingPrayer] = useState(null);
  const [date, setDate] = useState(null);
  const { toPDF, targetRef } = usePDF({
    filename: 'sunday-program.pdf',
    page: { 
      margin: 20,
      format: 'letter',
      orientation: 'portrait'
    }
  });

  const fetchHymns = async () => {
    const response = await fetch('/api/list_hymns');
    const data = await response.json();
    setHymns(data);
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchHymns();
    };

    loadData();
    initialLoad.current = false;
  }

  const hymnSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      width: "252px",
      textAlign: "left",
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
      fontWeight: 600
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "252px",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "left",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
      textAlign: "left",
      color: "black",
    }),
    container: (provided) => ({
      ...provided,
      width: "252px",
      position: "relative",
      textAlign: "left",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "black"
    })
  };

  const memberSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "15px",
      textAlign: "left",
      margin: "6px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "225px",
      left: "50%",
      transform: "translateX(-50%)",
      maxHeight: "200px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "2px", cursor: "pointer", borderBottom: "0.5px solid #e0e0e0" }}>
        <div style={{ width: "50px", height: "50px", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {data.member_photo || data.household_photo ? <img src={data.member_photo || data.household_photo} alt={data.label} style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "5px", border: "0.5px solid #B0B5C0" }} /> : <NoProfilePicture />}
        </div>
        <span style={{ flex: 1, textAlign: "left" }}>{data.label}</span>
      </div>
    );
  };

  return (
    <div className="container">
      <button 
        onClick={() => toPDF()}
        className="download-button"
      >
        Download PDF
      </button>

      <div 
        ref={targetRef} 
        className="page-preview"
      >
        <h1>
          Sacrament meeting -                   
          <DayPicker
            mode="single"
            selected={date}
            onSelect={setDate}
            disabled={day => day.getDay() !== 0}
            styles={{
              root: {
                fontSize: '14px'
              },
              day: {
                width: '36px',
                height: '36px',
                fontSize: '14px',
                padding: '2px',
                lineHeight: '36px',
                textAlign: 'center',
              },
              day_disabled: {
                opacity: 0.5,
              }
            }}
            style={{
              backgroundColor: "white",
              border: "1px solid #B0B5C0",
              borderRadius: "4px",
              padding: "4px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              zIndex: 9999,
            }}
          />
        </h1>

        <div className="section-header">Sustainings</div>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr>
              <th style={{ width: "50%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa" }}>Name</th>
              <th style={{ width: "50%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa" }}>Calling</th>
            </tr>
          </thead>
          <tbody>
            {sustainings.length > 0 ? (
              sustainings.map((sustaining, index) => (
                <tr key={index}>
                  <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(sustaining.recommended_name)}</td>
                  <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{sustaining.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} style={{ padding: "8px", border: "1px solid #B0B5C0", textAlign: "center", fontStyle: "italic" }}>
                  No sustainings
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="section-header">Releases</div>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr>
              <th style={{ width: "50%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa" }}>Name</th>
              <th style={{ width: "50%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa" }}>Calling</th>
            </tr>
          </thead>
          <tbody>
            {releases.length > 0 ? (
              releases.map((release, index) => (
                <tr key={index}>
                  <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(release.member_name)}</td>
                  <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{release.position}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} style={{ padding: "8px", border: "1px solid #B0B5C0", textAlign: "center", fontStyle: "italic" }}>
                  No releases
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="section-header">New Members</div>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
          <thead>
            <tr>
              <th style={{ width: "100%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa" }}>Name</th>
            </tr>
          </thead>
          <tbody>
            {newMembers.length > 0 ? (
              newMembers.map((newMember, index) => (
                <tr key={index}>
                  <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(newMember.name)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ padding: "8px", border: "1px solid #B0B5C0", textAlign: "center", fontStyle: "italic" }}>
                  No new members
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Select
          value={openingHymn}
          onChange={(option) => setOpeningHymn(option)}
          options={hymns}
          placeholder="Opening hymn"
          styles={hymnSelectStyles}
        />
        <Select
          value={sacramentHymn}
          onChange={(option) => setSacramentHymn(option)}
          options={hymns}
          placeholder="Sacrament hymn"
          styles={hymnSelectStyles}
        />
        <Select
          value={intermediateHymn}
          onChange={(option) => setIntermediateHymn(option)}
          options={hymns}
          placeholder="Intermediate hymn"
          styles={hymnSelectStyles}
        />
        <Select
          value={closingHymn}
          onChange={(option) => setClosingHymn(option)}
          options={hymns}
          placeholder="Closing hymn"
          styles={hymnSelectStyles}
        />
        <Select
          value={firstSpeaker}
          onChange={(option) => setFirstSpeaker(option)}
          options={membersList}
          placeholder="First speaker"
          styles={memberSelectStyles}
          components={{ Option: CustomOption }}
        />
        <Select
          value={secondSpeaker}
          onChange={(option) => setSecondSpeaker(option)}
          options={membersList}
          placeholder="Second speaker"
          styles={memberSelectStyles}
          components={{ Option: CustomOption }}
        />
        <Select
          value={thirdSpeaker}
          onChange={(option) => setThirdSpeaker(option)}
          options={membersList}
          placeholder="Third speaker"
          styles={memberSelectStyles}
          components={{ Option: CustomOption }}
        />
        <Select
          value={openingPrayer}
          onChange={(option) => setOpeningPrayer(option)}
          options={membersList}
          placeholder="Opening prayer"
          styles={memberSelectStyles}
          components={{ Option: CustomOption }}
        />
        <Select
          value={closingPrayer}
          onChange={(option) => setClosingPrayer(option)}
          options={membersList}
          placeholder="Closing prayer"
          styles={memberSelectStyles}
          components={{ Option: CustomOption }}
        />
      </div>
    </div>
  );
};