import React, { useState } from "react";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

export const MemberReportCard = ({ member, sessionInfo, unitDetails, membersList, setMembersList }) => {
  const [showDetails, setShowDetails] = useState(false);
  let currentPhotoSrc = member.member_photo || member.household_photo;

  const name = member.name.split(",").map((part) => part.trim()).reverse().join(" ");

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `3px solid #B0B5C0`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          height: "170px",
          width: "150px",
        }}
      >
        <div
          style={{
            height: "150px",
            width: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            position: "relative",
          }}
        >
          {member.notes?.length > 0 && (
            <Tippy content={member.notes.map(n => n.note).join(" • ")} theme="lcrplus">
              <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
            </Tippy>
          )}
          {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo" /> : <NoProfilePicture />}
        </div>
        <div className="name" onClick={() => setShowDetails(true)}>
          {name}
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </>
  );
};