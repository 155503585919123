export const SkeletonModal = () => {
  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal">
        <div
          className="member-card"
          style={{
            border: `3px solid #B0B5C0`,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {/* Photo skeleton */}
          <div style={{ margin: "15px" }}>
            <div
              style={{
                height: "380px",
                width: "380px",
                backgroundColor: "#EEF0F4",
                borderRadius: "8px",
                position: "relative",
                overflow: "hidden"
              }}
            >
              <div className="shimmer" />
            </div>
          </div>

          {/* Content block skeleton */}
          <div style={{ 
            margin: "0 15px 15px 15px",
            width: "380px",
            height: "160px",
            backgroundColor: "#EEF0F4",
            borderRadius: "8px",
            position: "relative",
            overflow: "hidden"
          }}>
            <div className="shimmer" />
          </div>
        </div>
      </div>
    </>
  );
};
